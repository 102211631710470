import { Component, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { TranslateService } from "@ngx-translate/core";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";
import { LogService } from "src/app/services/log.service";

import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-account-tools",
  templateUrl: "./account-tools.component.html",
  styleUrls: ["./account-tools.component.scss"],
})
export class AccountToolsComponent {
  showPrivacyModal = false;
  privacyChecked = false;
  agbChecked = false;
  notificationsActivated = true;

  private timer;

  constructor(
    private router: Router,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private apiUsersService: ApiUsersService,
    private apiSettingsService: ApiSettingsService,
    private translateService: TranslateService,
    private logService: LogService,
    private el: ElementRef,
    private authService: MsalService,
  ) {
    this.timer = setTimeout(() => {
      this.reloadImageConfig();
    }, 10 * 1000);
  }

  public switchMerchantAccess(hideClose?: boolean) {
    this.logService.debug("AccountTools.switchMerchantAccess()");
    this.uiShareService.commonModals.showSwitchMerchantContextModal(hideClose).subscribe((res) => {});
  }

  gotoUnprotectedRedirect() {
    if (sessionStorage.getItem("redirect")) {
      const redirect = sessionStorage.getItem("redirect");
      this.logService.debug("AccountTools.gotoUnprotectedRedirect() " + redirect);
      console.warn(redirect);
      if (redirect.startsWith("articleDetails")) {
        // goto mydata
        sessionStorage.removeItem("redirect");
        const number = redirect.replace("articleDetails:", "");
        this.router.navigateByUrl("/article/" + number);
      }
    }
  }

  gotoHomeOrRedirect() {
    if (sessionStorage.getItem("redirect")) {
      const redirect = sessionStorage.getItem("redirect");
      this.logService.debug("AccountTools.gotoUnprotectedRedirect() " + redirect);
      if (redirect.startsWith("order")) {
        // goto order
        const number = redirect.replace("order:", "");
        sessionStorage.removeItem("redirect");
        this.router.navigateByUrl("/order", { state: { search: number } });
      } else if (redirect.startsWith("offer")) {
        // goto offer
        const number = redirect.replace("offer:", "");
        sessionStorage.removeItem("redirect");
        this.router.navigateByUrl("/offer", { state: { search: number } });
      } else if (redirect.startsWith("shipment")) {
        // goto offer
        const number = redirect.replace("shipment:", "");
        sessionStorage.removeItem("redirect");
        this.router.navigateByUrl("/shipment-notes", {
          state: { search: number },
        });
      } else if (redirect.startsWith("mydata")) {
        // goto mydata
        sessionStorage.removeItem("redirect");
        this.router.navigateByUrl("/mydata");
      } else if (redirect.startsWith("articleDetails")) {
        // goto article details
        sessionStorage.removeItem("redirect");
        const number = redirect.replace("articleDetails:", "");
        this.router.navigateByUrl("/article/" + number);
      } else {
        sessionStorage.removeItem("redirect");
        // this.router.navigateByUrl("/home");
        this.router.navigateByUrl(redirect);
      }
    } else {
      this.router.navigateByUrl("/home");
    }
  }

  doClosePrivacyModal() {
    this.logService.debug("AccountTools.doClosePrivacyModal()");
    this.showPrivacyModal = false;
  }
  notificationsChanged(evt) {
    this.logService.debug("AccountTools.notificationsChanged()");
    this.notificationsActivated = evt;
  }
  privacyChanged(evt) {
    this.logService.debug("AccountTools.privacyChanged()");
    this.privacyChecked = evt;
  }
  agbChanged(evt) {
    this.logService.debug("AccountTools.agbChanged()");
    this.agbChecked = evt;
  }

  public reloadImageConfig(): void {
    this.uiShareService.loggedInUser$.subscribe((user) => {
      if (user) {
        if (localStorage.getItem("imageConfigLoadedDate")) {
          const dt = new Date();
          dt.setTime(Number(localStorage.getItem("imageConfigLoadedDate")));
          const diff = new Date().getTime() - dt.getTime();
          const minutes = diff / 1000 / 60;
          if (minutes >= 10) {
            this.postReloadImageConfig();
          }
        } else {
          this.postReloadImageConfig();
        }
      }
    });
  }
  private postReloadImageConfig() {
    this.apiSettingsService.getImageConfig().subscribe((next) => {
      localStorage.setItem("imageConfig", JSON.stringify(next));
      localStorage.setItem("imageConfigLoadedDate", "" + new Date().getTime());
    });
  }
}
