import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { MyMonitoringService } from "./my-monitoring.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private myMonitoringService: MyMonitoringService) {
    super();
  }

  handleError(error: Error) {
    if (!environment.production) {
      console.error(error);
    }
    this.myMonitoringService.logException(error); // Manually log exception
  }
}
