import { Component, NgZone } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, EMPTY, Observable, Observer, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ISortOption } from "src/app/models/sort";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";
import { UiShareService } from "src/app/services/ui-share.service";
@Component({
  selector: "app-common-modals",
  templateUrl: "./common-modals.component.html",
  styleUrls: ["./common-modals.component.scss"],
})
export class CommonModalsComponent {
  public showCommonErrorModal = false;
  public loginMessage = "";
  public isOpenLoginMessageDialog = false;
  public extraButtonTitle = "";
  public title = "";
  public text = "";
  private errorModalClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private errorModalExtraSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private countrySelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private sortOptionSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private switchAccountSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private switchAccountAddedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public countryOptionsSrc = [];
  public countryOptions = [];
  public isOpenCountryDialog = false;
  private selectedCountry;
  public countrySearchText;
  public isOpenSortOptionDialog = false;
  public sortOptions = [];
  private selectedSortOption;
  public isOpenSwitchAccountDialog = false;
  private selectedAccount;
  private updatedAccounts;
  public isOpenSwitchAccountAddDialog = false;
  public isOpenSwitchMerchantContextDialog = false;
  public isOpenSwitchAccountAddedDialog = false;

  constructor(
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private apiUserService: ApiUsersService,
    private loaderService: LoaderService,
    private ngZone: NgZone,
  ) {}

  closeLoginMessageDialog() {
    this.isOpenLoginMessageDialog = false;
  }
  showLoginMessageDialog(message: string) {
    this.loginMessage = message;
    this.isOpenLoginMessageDialog = true;
  }

  errorModalClosed() {
    this.showCommonErrorModal = false;
    this.errorModalClosedSubject.next(true);
  }
  errorModalExtraClicked() {
    this.showCommonErrorModal = false;
    this.errorModalExtraSubject.next(true);
  }
  private errorModalSubscription1;
  private errorModalSubscription2;
  public showErrorModal(title: string, text: string, extraButtonTitle: string = ""): Observable<number> {
    // reset
    this.errorModalClosedSubject.next(false);
    this.title = title != null ? title : this.translateService.instant("COMMON.ERROR");
    this.text = text;
    this.extraButtonTitle = extraButtonTitle;
    this.showCommonErrorModal = true;
    return new Observable((observer: Observer<number>) => {
      this.errorModalSubscription1 = this.errorModalClosedSubject.subscribe((next) => {
        if (next) {
          this.errorModalSubscription1.unsubscribe();
          observer.next(0);
          observer.complete();
        }
      });
      this.errorModalSubscription2 = this.errorModalExtraSubject.subscribe((next) => {
        if (next) {
          this.errorModalSubscription2.unsubscribe();
          observer.next(1);
          observer.complete();
        }
      });
    });
  }

  public selectCountry(country: any): void {
    this.selectedCountry = country;
    this.countrySelectedSubject.next(true);
  }
  public closeCountryDialog(): void {
    this.selectedCountry = null;
    this.countrySelectedSubject.next(true);
  }
  public countrySearchChanged(): void {
    const tmp = this.countrySearchText.toLowerCase();
    this.countryOptions = this.countryOptionsSrc.filter((c) => c.title.toLowerCase().indexOf(tmp) != -1);
  }

  private countrySubscription;
  public showCountryModal(): Observable<any> {
    this.countrySelectedSubject.next(false);
    this.countryOptionsSrc = this.uiShareService.getCountryOptions(this.translateService.currentLang);
    this.countryOptions = this.countryOptionsSrc;
    this.selectedCountry = null;
    this.isOpenCountryDialog = true;
    return new Observable((observer: Observer<boolean>) => {
      this.countrySubscription = this.countrySelectedSubject.subscribe((next) => {
        if (next) {
          this.isOpenCountryDialog = false;
          this.countrySubscription.unsubscribe();
          observer.next(this.selectedCountry);
          observer.complete();
        }
      });
    });
  }

  public selectSortOption(option: any): void {
    this.selectedSortOption = option;
    this.sortOptionSelectedSubject.next(true);
  }
  public closeSortOptionDialog(): void {
    this.selectedSortOption = null;
    this.sortOptionSelectedSubject.next(true);
  }
  private sortOptionSubscription;
  public showSortOptionModal(sortOptions: ISortOption[]): Observable<any> {
    this.sortOptionSelectedSubject.next(false);
    this.sortOptions = sortOptions;
    this.selectedSortOption = null;
    this.isOpenSortOptionDialog = true;
    return new Observable((observer: Observer<boolean>) => {
      this.sortOptionSubscription = this.sortOptionSelectedSubject.subscribe((next) => {
        if (next) {
          this.isOpenSortOptionDialog = false;
          this.sortOptionSubscription.unsubscribe();
          observer.next(this.selectedSortOption);
          observer.complete();
        }
      });
    });
  }

  merchantsRead;
  merchantsReadFiltered;
  merchantsWrite;
  merchantsWriteFiltered;
  hideSwitchMerchantContextDialogClose: boolean;
  merchantSearchCtrl = new UntypedFormControl();
  merchantSearchCtrlSub: Subscription;
  public showSwitchMerchantContextModal(hideClose?: boolean) {
    this.hideSwitchMerchantContextDialogClose = hideClose || false;
    this.loaderService.showLoader();
    this.apiUserService.getMyMerchantAccessInfos().subscribe({
      next: (res) => {
        this.merchantsRead = res.readAccess;
        this.merchantsReadFiltered = res.readAccess;
        this.merchantsWrite = res.writeAccess;
        this.merchantsWriteFiltered = res.writeAccess;
      },
      complete: () => {
        this.loaderService.hideLoader();
        this.isOpenSwitchMerchantContextDialog = true;
        this.merchantSearchCtrlSub = this.merchantSearchCtrl.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
          this.filterMerchants(newValue?.toLowerCase()?.split(" "));
        });
      },
    });
    return EMPTY;
  }
  filterMerchants(value: any) {
    if (this.merchantsRead) {
      this.merchantsReadFiltered = this.merchantsRead.filter((m) =>
        value.every((e) => m.merchantNumber?.toLowerCase()?.includes(e) || m.companyName?.toLowerCase()?.includes(e) || m.email?.toLowerCase()?.includes(e)),
      );
    }
    if (this.merchantsWrite) {
      this.merchantsWriteFiltered = this.merchantsWrite.filter((m) =>
        value.every((e) => m.merchantNumber?.toLowerCase()?.includes(e) || m.companyName?.toLowerCase()?.includes(e) || m.email?.toLowerCase()?.includes(e)),
      );
    }
  }
  closeSwitchMerchantContextDialog() {
    this.isOpenSwitchMerchantContextDialog = false;
    this.merchantSearchCtrl.reset();
    this.merchantSearchCtrlSub?.unsubscribe();
  }
  switchMerchantContext(merchant: any) {
    localStorage.setItem("SwitchSharedMerchant", merchant.id);
    this.apiUserService.switchMerchantContext(merchant.id).subscribe((res) => {
      window.location.reload();
    });
  }
  leaveMerchantContext() {
    this.apiUserService.leaveMerchantContext().subscribe({
      complete: () => {
        window.location.reload();
      },
    });
  }
  logout() {
    this.uiShareService.logout();
  }
}
