<app-custom-ion-header [selected]="'MENU.OFFER_REQUEST_AGAIN'" [homeIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.OFFER_REQUEST_AGAIN'" [homeIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.OFFER_REQUEST_AGAIN'" [homeIcon]="true"></app-custom-ion-condense-header>
  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header contentTitle="Erneut anfragen"></app-content-header>
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.OFFER_NUMBER" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="typeNumber" />
            </div>
          </div>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.NAME" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="contactName" />
            </div>
          </div>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.EMAIL" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="email" />
            </div>
          </div>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.MESSAGE" | translate }}*</label>
            <div class="field">
              <textarea class="form-control" formControlName="message"></textarea>
            </div>
          </div>
          <div class="privacy">
            <label>{{ "PRIVACY.DATA_PRIVACY_PART_1" | translate }}</label>
            <a class="link" (click)="uiShareService.accountTools.showPrivacyModal = true">{{ "PRIVACY.DATA_PRIVACY_PART_2" | translate }}</a>
            <label>{{ "PRIVACY.DATA_PRIVACY_PART_3" | translate }}</label>
          </div>
          <div>
            <div class="field-checkbox">
              <input id="acceptPrivacy" class="form-control" type="checkbox" formControlName="acceptPrivacy" />
              <label for="acceptPrivacy" class="cell-text-checkbox">{{ "PRIVACY.ACCEPT_PRIVACY" | translate }}</label>
            </div>
            <div class="field-checkbox">
              <input id="sendCopy" class="form-control" type="checkbox" formControlName="sendCopy" />
              <label for="sendCopy" class="cell-text-checkbox">Kopie dieser Anfrage an {{ form?.get("email")?.value }}</label>
            </div>
          </div>
          <div>
            <button type="submit" [disabled]="form.invalid">
              {{ "CONTACT_FORM.SEND" | translate }}
            </button>
          </div>
        </form>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
