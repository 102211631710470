import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  public doClosePrivacyModal(): void {
    this.router.navigateByUrl("/");
  }
}
