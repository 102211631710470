import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { RequestAgain } from "src/app/models/contact";
import { ApiContactService } from "src/app/services/api/api-contact.service";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { LoaderService } from "src/app/services/loader.service";
import { LogService } from "src/app/services/log.service";
import { MessageService } from "src/app/services/message.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-offer-request-again",
  templateUrl: "./offer-request-again.component.html",
  styleUrls: ["./offer-request-again.component.scss"],
})
export class OfferRequestAgainComponent implements OnInit {
  public form;
  public typeNumber: string;
  public breadcrumb: Breadcrumb[];

  constructor(
    private route: ActivatedRoute,
    private contactApi: ApiContactService,
    private log: LogService,
    private loaderService: LoaderService,
    private location: Location,
    private router: RoutingService,
    public uiShareService: UiShareService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private apiSettingsService: ApiSettingsService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe({
      next: (param) => {
        this.typeNumber = param["id"];

        try {
          this.breadcrumb = [
            <Breadcrumb>{
              title: this.translateService.instant("MENU.OFFER"),
              page: "offer",
            },
            <Breadcrumb>{
              title: this.translateService.instant("MENU.OFFER_REQUEST_AGAIN"),
              page: null,
            },
          ];
        } catch (e) {}
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.uiShareService.loggedInUser$.subscribe((user) => {
      if (!user) {
        return;
      }
      const name = user.firstName + " " + user.lastName;
      const email = user.email;

      this.form = this.formBuilder.group({
        typeNumber: [{ value: this.typeNumber, disabled: !!this.typeNumber }, Validators.required],
        contactName: [{ value: name, disabled: !!name }, Validators.required],
        email: [{ value: email, disabled: !!email }, Validators.required],
        message: [
          `Guten Tag,

bitte erstellen Sie mir ein aktuelles Angebot.`,
          Validators.required,
        ],
        acceptPrivacy: [false, Validators.requiredTrue],
        sendCopy: [true],
      });
    });
  }

  public onSubmit(): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      const formValue = this.form.value;

      const contactObject: RequestAgain = new RequestAgain();
      contactObject.sendCopy = formValue.sendCopy;
      contactObject.number = this.typeNumber;
      contactObject.message = formValue.message;
      this.contactApi.sendOfferRequestAgain(contactObject).subscribe({
        next: (data) => {
          this.messageService.success(this.translateService.instant("CONTACT_FORM.SUCCESS_SEND"));

          this.location.back();
          this.loaderService.hideLoader();
        },
        error: (error) => {
          this.log.error(error);
          this.messageService.error(this.translateService.instant("CONTACT_FORM.ERROR_SEND"));
          this.loaderService.hideLoader();
        },
      });
    });
  }
}
