import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import moment from "moment";

declare let noop: any;
declare let Error: any;
//declare let moment: any;

const methods = ["error", "warn", "log", "info", "debug", "trace"];

@Injectable()
export class LogService {
  debug(...args: any[]) {
    this.consoleLog("debug", args);
  }

  error(...args: any[]) {
    this.consoleLog("error", args);
  }

  info(...args: any[]) {
    this.consoleLog("info", args);
  }

  log(...args: any[]) {
    this.consoleLog("log", args);
  }

  trace(...args: any[]) {
    this.consoleLog("trace", args);
  }

  warn(...args: any[]) {
    this.consoleLog("warn", args);
  }

  private formatError(arg: any) {
    if (arg instanceof Error) {
      if (arg.stack) {
        arg = arg.message && arg.stack.indexOf(arg.message) === -1 ? "Error: " + arg.message + "\n" + arg.stack : arg.stack;
      } else if (arg.sourceURL) {
        arg = arg.message + "\n" + arg.sourceURL + ":" + arg.line;
      }
    } else if (typeof arg === "string") {
      return arg;
    } else if (typeof arg === "object") {
      if (Array.isArray(arg)) {
        arg = Array.prototype.join.call(arg);
      } else {
        arg = JSON.stringify(arg);
      }
    } else if (typeof arg !== "string") {
      arg = JSON.stringify(arg);
    }
    return arg;
  }
  public printBuffer(): void {
    this.buffer.forEach((b) => {
      console.log("BUFFER> " + b);
    });
  }
  private buffer: any[] = [];
  private consoleLog(type: string, args: any[]) {
    console.log("aldra_logx: " + args);
  }
}
