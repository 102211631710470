import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { debounceTime } from "rxjs/operators";
import { User, UserWorkflowState } from "src/app/models/user";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";

import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-switch-user",
  templateUrl: "./admin-switch-user.component.html",
  styleUrls: ["./admin-switch-user.component.scss"],
})
export class AdminSwitchUserComponent implements OnInit {
  public form: FormGroup;
  public users: User[] = [];
  public usersFiltered: User[] = [];
  constructor(
    public dialogRef: MatDialogRef<AdminSwitchUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fromBuilder: FormBuilder,
    private apiUsersService: ApiUsersService,
    private loaderService: LoaderService,
    private uiShareService: UiShareService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.form = this.fromBuilder.group({
      userSearch: [""],
    });

    // this.loaderService.showLoaderDelayed().subscribe(() => {
    this.apiUsersService.getAllUser().subscribe({
      next: (data: User[]) => {
        this.users = data
          .filter((u) => u.workflowState == UserWorkflowState.Confirmed)
          .sort((u1, u2) => {
            if (u1.merchantNumber > u2.merchantNumber) return 1;
            if (u1.merchantNumber < u2.merchantNumber) return -1;
            return 0;
          });
        this.usersFiltered = this.users;
        // this.loaderService.hideLoader();
      },
      error: (e) => {
        console.log(e);
        // this.loaderService.hideLoader();
      },
    });
    // });

    this.form
      .get("userSearch")
      .valueChanges.pipe(debounceTime(300))
      .subscribe((filter) => {
        this.usersFiltered = this.users.filter((user) => {
          let term = user.merchantNumber + user.email;
          term = term.toLowerCase().trim();
          filter = filter.toLowerCase().trim();
          return filter.split(" ").every((e) => term.includes(e));
        });
      });

    this.dialogRef.afterClosed().subscribe(() => this.loaderService.hideLoader());
  }

  switchUser(user: User) {
    this.router.navigateByUrl("/home");
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      localStorage.setItem("SwitchUser", user.id);
      window.location.reload();
    });
  }
}
