<app-custom-ion-header #header [selected]="'MENU.RECLAMATION_LIST'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header #header (onReload)="reloadData(true)" [selected]="'MENU.RECLAMATION_LIST'" [homeIcon]="true" [reloadIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header #header [selected]="'MENU.RECLAMATION_LIST'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-condense-header>

  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb" [ngClass]="appMode ? 'appMode' : ''">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'RECLAMATIONS.TITLE' | translate"></app-content-header>
        <div *ngIf="!appMode" class="reload" (click)="reloadData(true)">
          {{ "COMMON.RELOAD" | translate }}
        </div>
        <br />

        <button *ngIf="!uiShareService.readOnlyMode()" (click)="createReclamation()">
          {{ "RECLAMATIONS.NEW" | translate }}
        </button>
        <button *ngIf="!uiShareService.readOnlyMode()" (click)="createReclamation('reclamation-glass')">
          {{ "RECLAMATIONS.NEW_GLAS" | translate }}
        </button>
        <button *ngIf="!uiShareService.readOnlyMode() && currentTab.id !== 2" [disabled]="selectedItemCount() === 0" (click)="batchArchive()">
          Archivieren ({{ selectedItemCount() }})
        </button>
        <br />
        <div class="app-header" style="align-items: center">
          <div class="search-comp">
            <app-search-box  [formControl]="globalSearchField"></app-search-box>
          </div>
        </div>
        <br />
        <ng-container *ngFor="let tabGroup of tabGroups">
          <div class="tab" (click)="switchTab(tabGroup, tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabGroup.tabs">
            <ng-container *ngIf="!tab.needReload && tab.count > -1; else nocount"> {{ tab.title }} ({{ tab.searchCount ?? tab.count }}) </ng-container>
            <ng-template #nocount>
              {{ tab.title }}
            </ng-template>
          </div>
        </ng-container>
        <br />
        <br />
        <div class="tab" (click)="switchSubTab(tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of currentTab.subTabs">
          <ng-container *ngIf="!tab.needReload; else nocount"> {{ tab.title }} ({{ tab.searchCount ?? tab.count }}) </ng-container>
          <ng-template #nocount>
            {{ tab.title }}
          </ng-template>
        </div>
        <br />
        <br />
        <div class="main-view">
          <div class="desktop">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" (click)="addOrRemove(expandedRows, row); $event.stopPropagation()">
                  <button class="button-table">
                    <span *ngIf="!expandedRows.includes(row)" class="add"></span>
                    <span *ngIf="expandedRows.includes(row)" class="minus"></span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <span>
                    <input [ngModel]="allSelected()" [ngModelOptions]="{ standalone: true }" type="checkbox" (click)="selectAll($event)" />
                  </span>
                </th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                  <span>
                    <input #checkbox type="checkbox" [(ngModel)]="row.selected" [ngModelOptions]="{ standalone: true }" />
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="reclamationNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorgang</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.reclamationNumber ? row.reclamationNumber : row.portalNumber || "-" }}</td>
              </ng-container>

              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getFixedDate(row.createdDate) }}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">{{ "RECLAMATION_STATUS." + row.status | translate }}</td>
              </ng-container>

              <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Auftragsnummer</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.orderNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name Endkunde</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.customerName }}</td>
              </ng-container>

              <ng-container matColumnDef="contactPerson">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ansprechpartner</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.contactPerson }}</td>
              </ng-container>

              <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Produkt</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.product || "-" }}</td>
              </ng-container>

              <ng-container matColumnDef="errorDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fehlergrund</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.errorDescription }}</td>
              </ng-container>

              <ng-container matColumnDef="orderNumberInfo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Auftragsnummer Info</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.orderNumberInfo }}</td>
              </ng-container>

              <ng-container matColumnDef="positions2SidedGlasStr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lage/Position bei 2-fach Verglasung</th>
                <td mat-cell *matCellDef="let row">{{ row.positions2SidedGlasStr }}</td>
              </ng-container>

              <ng-container matColumnDef="positions3SidedGlasStr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lage/Position bei 3-fach Verglasung</th>
                <td mat-cell *matCellDef="let row">{{ row.positions3SidedGlasStr }}</td>
              </ng-container>

              <ng-container matColumnDef="photosCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fotos</th>
                <td mat-cell *matCellDef="let row">{{ row.photosCount }}</td>
              </ng-container>

              <ng-container matColumnDef="writings">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Berichte</th>
                <td mat-cell *matCellDef="let row">{{ row.writings?.length }}</td>
              </ng-container>

              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Kontakt</th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                  <div class="message-container">
                    <img class="message mail" (click)="openContactForm('contact', row)" src="./assets/icons/email-02.svg" alt="" />
                    <span class="vertical-line"></span>
                    <img class="message tel" (click)="openContactForm('callback', row)" src="./assets/icons/telefon-01.svg" alt="" />
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!expandedRows.includes(row)">
                  <ng-container *ngIf="expandedRows.includes(row)">
                    <app-reclamation-detail [reclamation]="row"></app-reclamation-detail>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="row.status === 1 ? 'locked' : ''"></tr>
              <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                  Keine Reklamationen gefunden<span *ngIf="currentTab.id !== 2"
                  >, im
                  <a (click)="switchToArchive()">Archiv</a>
                  suchen?
                </span></td>
              </tr>
            </table>
            <mat-paginator
              [hidden]="dataSource.filteredData.length < environment.ENTRIES_PER_PAGE"
              [pageSize]="environment.ENTRIES_PER_PAGE"
              hidePageSize
              showFirstLastButtons
            ></mat-paginator>
          </div>

          <div class="mobile">
            <app-pager #pager1 (pageChanged)="onPageChanged($event)"></app-pager>

            <mat-card class="card mat-mdc-elevation-specific mat-elevation-z2" *ngFor="let item of mobileData; let i = index" appearance="raised">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title class="card-title">
                    <mat-checkbox
                      class="no-ripple-checkbox"
                      color="primary"
                      [disableRipple]="true"
                      *ngIf="currentTab?.id !== 2 && !uiShareService.readOnlyMode()"
                      [(ngModel)]="item.selected"
                      [ngModelOptions]="{ standalone: true }"
                    ></mat-checkbox>
                    <span class="title" #highlightCell>{{ item?.reclamationNumber ? item?.reclamationNumber : item?.portalNumber }}</span>
                    <span #highlightCell class="date-mobile" *ngIf="item.createdDate !== null">({{ getFixedDate(item.createdDate) }})</span>
                  </mat-card-title>
                  <mat-card-subtitle #highlightCell>{{ item.customerName }}</mat-card-subtitle>
                  <button *ngIf="!uiShareService.readOnlyMode()" class="menu-button more-button" mat-icon-button [matMenuTriggerFor]="actionMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item (click)="openContactForm('contact', item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">email</mat-icon>
                      <span>Kontakt</span>
                    </button>
                  </mat-menu>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content class="content">
                <dl class="grid">
                  <dt>Status:</dt>
                  <dd>{{ "RECLAMATION_STATUS." + item.status | translate }}</dd>

                  <dt>Auftragsnummer:</dt>
                  <dd>{{ item.orderNumber }}</dd>

                  <dt>Name Endkunde:</dt>
                  <dd>{{ item.customerName }}</dd>

                  <dt>Ansprechpartner:</dt>
                  <dd>{{ item.contactPerson }}</dd>

                  <ng-container *ngIf="currentTab.id === 0 || (currentTab.id === 2 && currentSubTab.id === 0)">
                    <dt>Produkt:</dt>
                    <dd>{{ item.product }}</dd>

                    <dt>Fehlergrund:</dt>
                    <dd>{{ item.errorDescription }}</dd>
                  </ng-container>

                  <ng-container *ngIf="currentTab.id === 1 || (currentTab.id === 2 && currentSubTab.id === 1)">
                    <dt>Auftragsnummer Info:</dt>
                    <dd>{{ item.orderNumberInfo }}</dd>

                    <dt>Lage/Position bei 2-fach Verglasung:</dt>
                    <dd>{{ item.positions2SidedGlasStr }}</dd>

                    <dt>Lage/Position bei 3-fach Verglasung:</dt>
                    <dd>{{ item.positions3SidedGlasStr }}</dd>
                  </ng-container>

                  <dt>Fotos:</dt>
                  <dd>{{ item.photosCount }}</dd>

                  <dt>Berichte:</dt>
                  <dd>{{ item.writings?.length }}</dd>
                </dl>
              </mat-card-content>
              <mat-card-actions class="card-actions">
                <button class="menu-button" *ngIf="!expandedRows.includes(item)" mat-icon-button (click)="addOrRemove(expandedRows, item)">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button class="menu-button" *ngIf="expandedRows.includes(item)" mat-icon-button (click)="addOrRemove(expandedRows, item)">
                  <mat-icon>expand_less</mat-icon>
                </button>
              </mat-card-actions>
              <mat-card-footer class="footer" *ngIf="expandedRows.includes(item)">
                <app-reclamation-detail [reclamation]="item"></app-reclamation-detail>
              </mat-card-footer>
            </mat-card>

            <div *ngIf="mobileData.length === 0">Keine Reklamationen gefunden<span *ngIf="currentTab.id !== 2"
              >, im
              <a (click)="switchToArchive()">Archiv</a>
              suchen?
            </span></div>
            <app-pager #pager2 (pageChanged)="onPageChanged($event)"></app-pager>
          </div>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
