import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { Setting } from "src/app/models/setting";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { LogService } from "src/app/services/log.service";
import { RoutingService } from "src/app/services/routing.service";
import { dateTimeValidator } from "src/app/validators/time-validator";
import { Breadcrumb } from "../../models/breadcrumb";
import { Callback, Contact, ContactType } from "../../models/contact";
import { ApiContactService } from "../../services/api/api-contact.service";
import { LoaderService } from "../../services/loader.service";
import { MessageService } from "../../services/message.service";
import { UiShareService } from "../../services/ui-share.service";

@Component({
  selector: "app-contact-formular",
  templateUrl: "./contact-formular.component.html",
  styleUrls: ["./contact-formular.component.scss"],
})
export class ContactFormularComponent implements OnInit, OnDestroy {
  public type: string = "";
  public routeInfo: string = "";
  public typeNumber: string;
  public form: UntypedFormGroup;
  private subscription: Subscription;
  public breadcrumb: Breadcrumb[];
  public homeIcon: boolean = true;
  public mode?: "contact" | "callback" = undefined;
  public startingHour = [-1, 7, 7, 7, 7, 7, -1]; // Sunday - Saturday
  public closingHour = [-1, 17, 17, 17, 17, 14, -1]; // Sunday - Saturday
  public readonly dateFormat = "YYYY-MM-DD";
  public readonly timeFormat = "HH:mm";
  private holidaySettings: Setting[] = [];
  private dateTimeValidator: ValidatorFn;

  constructor(
    private route: ActivatedRoute,
    private contactApi: ApiContactService,
    private log: LogService,
    private loaderService: LoaderService,
    private location: Location,
    private router: RoutingService,
    public uiShareService: UiShareService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private apiSettingsService: ApiSettingsService,
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe({
      next: (param) => {
        this.type = param["type"];
        this.typeNumber = param["id"];
        this.routeInfo = param["info"];
        //console.log(this.type);
        //console.log(this.typeNumber);
        //console.log(this.routeInfo);
        try {
          this.breadcrumb = [
            <Breadcrumb>{
              title: this.translateService.instant("MENU." + this.type.toUpperCase()),
              page: this.type,
            },
            <Breadcrumb>{
              title: this.translateService.instant("MENU.CONTACT_FORM"),
              page: null,
            },
          ];
        } catch (e) {
          //ignore
        }
        if (this.routeInfo) {
          this.homeIcon = false;
          if (this.routeInfo == "order-details") {
            const insert = <Breadcrumb>{
              title: this.typeNumber,
              page: { name: "order-details", item: window.history.state.order },
            };
            this.breadcrumb.splice(1, 0, insert);
          } else if (this.routeInfo == "offer-details") {
            const insert = <Breadcrumb>{
              title: this.typeNumber,
              page: { name: "offer-details", item: window.history.state.offer },
            };
            this.breadcrumb.splice(1, 0, insert);
          } else if (this.routeInfo == "frame-details") {
            const insert = <Breadcrumb>{
              title: this.typeNumber,
              page: { name: "frame-details", item: window.history.state.frame },
            };
            this.breadcrumb.splice(1, 0, insert);
          } else if (this.routeInfo == "reclamation-details") {
            const insert = <Breadcrumb>{
              title: this.typeNumber,
              page: {
                name: "reclamation-details",
                item: window.history.state.reclamation,
              },
            };
            this.breadcrumb.splice(1, 0, insert);
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.uiShareService.loggedInUser$.subscribe((user) => {
      if (!user) {
        return;
      }
      const name = user.firstName + " " + user.lastName;
      const email = user.email;
      const tel = user.phone;

      this.form = this.formBuilder.group(
        {
          typeNumber: [{ value: this.typeNumber, disabled: !!this.typeNumber }, Validators.required],
          contactName: [{ value: name, disabled: !!name }, Validators.required],
          email: [{ value: email, disabled: !!email }, Validators.required],
          tel: [tel, Validators.required],
          contact: [name],
          message: ["", Validators.required],
          acceptPrivacy: [false, Validators.requiredTrue],
          sendCopy: [true],
          date: [this.getStartDate(), Validators.required],
          time: [this.getStartTime(), Validators.required],
        },
        { validators: this.dateTimeValidator },
      );

      this.form.get("date").valueChanges.subscribe((date) => {
        if (this.isOpen()) {
          this.form.get("time").enable();
        } else {
          this.form.get("time").disable();
        }
      });

      this.form.get("time").valueChanges.subscribe((time) => {
        const mTime = moment(time, this.timeFormat, true);
        mTime.startOf("hours");
        this.form.get("time").patchValue(mTime.format(this.timeFormat), { emitEvent: false });
      });

      this.apiSettingsService.getHolidaySettings().subscribe({
        next: (res) => {
          this.holidaySettings = res;
          this.dateTimeValidator = dateTimeValidator("date", "time", this.startingHour, this.closingHour, this.holidaySettings);
          this.form.clearValidators();
          if (this.mode === "callback") {
            this.form.addValidators(this.dateTimeValidator);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });

      const path = this.route.snapshot.url[0].path;
      if (path === "contact") {
        this.setMode("contact");
      } else {
        this.setMode("callback");
      }
    });
  }

  public setMode(mode: "contact" | "callback") {
    console.log(this.mode);
    console.log(mode);
    if (this.mode === mode) {
      return;
    }
    this.mode = mode;
    if (mode === "contact") {
      this.form.clearValidators();
      ["tel", "date", "time"].forEach((field) => {
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      });
      this.form.get("message").addValidators(Validators.required);
      this.form.get("message").updateValueAndValidity();
    } else if (mode === "callback") {
      this.form.addValidators(this.dateTimeValidator);
      ["tel", "date", "time"].forEach((field) => {
        this.form.get(field).addValidators(Validators.required);
        this.form.get(field).updateValueAndValidity();
      });
      this.form.get("message").clearValidators();
      this.form.get("message").updateValueAndValidity();
    }
  }

  public getStartDate() {
    const now = moment();
    if (now.get("minutes") >= 30) {
      now.add(1, "hours");
    }
    if (now.get("hours") >= this.closingHour[now.day()]) {
      do {
        // get next day with startingHour > -1
        now.add(1, "day");
      } while (this.startingHour[now.day()] < 0);
    }
    return now.format(this.dateFormat);
  }

  public getTimeframeEnd() {
    const now = moment(this.form.get("date").value + this.form.get("time").value, this.dateFormat + this.timeFormat, true);
    now.set("hours", Math.min(now.get("hours") + 2, this.closingHour[now.day()]));
    return now.format(this.timeFormat);
  }

  private getStartTime() {
    const now = moment();
    if (now.get("minutes") >= 30) {
      now.add(1, "hours");
    }
    if (now.get("hours") >= this.closingHour[now.day()]) {
      do {
        // get next day with startingHour > -1
        now.add(1, "day");
      } while (this.startingHour[now.day()] < 0);
      now.set("hours", this.startingHour[now.day()]);
    }
    now.startOf("hours");
    return now.format(this.timeFormat);
  }

  public onSubmit(): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      const formValue = this.form.value;

      if (this.mode === "contact") {
        this.submitContact(formValue);
      } else {
        this.submitCallback(formValue);
      }
    });
  }

  private submitContact(formValue: any) {
    const contactObject: Contact = new Contact();
    contactObject.sendCopy = formValue.sendCopy;
    contactObject.number = this.typeNumber;
    contactObject.body = "";
    if (this.type == "order") {
      contactObject.type = ContactType.Order;
      contactObject.body += this.translateService.instant("CONTACT_FORM.ORDER_NUMBER") + ": ";
    } else if (this.type == "shipment-notes") {
      contactObject.type = ContactType.ShipmentNote;
      contactObject.body += this.translateService.instant("CONTACT_FORM.SHIPMENT_NUMBER") + ": ";
    } else if (this.type == "offer") {
      contactObject.type = ContactType.Offer;
      contactObject.body += this.translateService.instant("CONTACT_FORM.OFFER_NUMBER") + ": ";
    } else if (this.type == "reclamation") {
      contactObject.type = ContactType.Reclamation;
      contactObject.body += this.translateService.instant("CONTACT_FORM.RECLAMATION_NUMBER") + ": ";
    } else if (this.type == "frame") {
      contactObject.type = ContactType.Frame;
      contactObject.body += this.translateService.instant("CONTACT_FORM.FRAME_NUMBER") + ": ";
    }
    contactObject.body += this.typeNumber;
    contactObject.body += "\n" + this.translateService.instant("CONTACT_FORM.MESSAGE") + ": " + formValue.message;
    this.contactApi.sendContact(contactObject).subscribe({
      next: (data) => {
        this.messageService.success(this.translateService.instant("CONTACT_FORM.SUCCESS_SEND"));

        this.routeBack();
        this.loaderService.hideLoader();
      },
      error: (error) => {
        this.log.error(error);
        this.messageService.error(this.translateService.instant("CONTACT_FORM.ERROR_SEND"));
        this.loaderService.hideLoader();
      },
    });
  }

  private submitCallback(formValue: any) {
    const callbackObject: Callback = new Callback();
    callbackObject.sendCopy = formValue.sendCopy;
    callbackObject.number = this.typeNumber;
    callbackObject.body = "";
    if (this.type == "order") {
      callbackObject.type = ContactType.Order;
      callbackObject.body += this.translateService.instant("CONTACT_FORM.ORDER_NUMBER") + ": ";
    } else if (this.type == "shipment-notes") {
      callbackObject.type = ContactType.ShipmentNote;
      callbackObject.body += this.translateService.instant("CONTACT_FORM.SHIPMENT_NUMBER") + ": ";
    } else if (this.type == "offer") {
      callbackObject.type = ContactType.Offer;
      callbackObject.body += this.translateService.instant("CONTACT_FORM.OFFER_NUMBER") + ": ";
    } else if (this.type == "reclamation") {
      callbackObject.type = ContactType.Reclamation;
      callbackObject.body += this.translateService.instant("CONTACT_FORM.RECLAMATION_NUMBER") + ": ";
    } else if (this.type == "frame") {
      callbackObject.type = ContactType.Frame;
      callbackObject.body += this.translateService.instant("CONTACT_FORM.FRAME_NUMBER") + ": ";
    }
    callbackObject.body += this.typeNumber;
    callbackObject.body += "\n" + this.translateService.instant("CONTACT_FORM.MESSAGE") + ": " + formValue.message;
    callbackObject.phone = formValue.tel;
    callbackObject.contactPerson = formValue.contact;
    const dateFrom = moment(formValue.date + formValue.time, this.dateFormat + this.timeFormat, true).startOf("hours");
    callbackObject.dateTimeFrom = dateFrom.toDate();
    callbackObject.dateTimeTo = dateFrom.set("hours", Math.min(dateFrom.get("hours") + 2, this.closingHour[dateFrom.day()])).toDate();
    callbackObject.message = formValue.message;
    this.contactApi.sendCallback(callbackObject).subscribe({
      next: (data) => {
        this.messageService.success(this.translateService.instant("CONTACT_FORM.SUCCESS_SEND"));

        this.routeBack();
        this.loaderService.hideLoader();
      },
      error: (error) => {
        this.log.error(error);
        this.messageService.error(this.translateService.instant("CONTACT_FORM.ERROR_SEND"));
        this.loaderService.hideLoader();
      },
    });
  }

  private routeBack() {
    if (this.routeInfo && this.routeInfo != "-") {
      if (this.routeInfo == "order-details") {
        this.router.forwardByUrl("/order-details", {
          state: { order: window.history.state.order },
        });
      } else if (this.routeInfo == "offer-details") {
        this.router.forwardByUrl("/offer-details", {
          state: { offer: window.history.state.offer },
        });
      } else if (this.routeInfo == "reclamation-details") {
        this.router.forwardByUrl("/reclamation-details", {
          state: { reclamation: window.history.state.reclamation },
        });
      } else if (this.routeInfo == "frame-details") {
        this.router.forwardByUrl("/frame-details", {
          state: { reclamation: window.history.state.frame },
        });
      }
    } else {
      this.location.back();
    }
  }

  public getStartingHourFormatted() {
    const selectedDate = this.form.get("date").value;
    const weekday = moment(selectedDate, this.dateFormat, true).day();
    return weekday ? moment().set("hours", this.startingHour[weekday]).startOf("hours").format(this.timeFormat) : "";
  }

  public getClosingHourFormatted() {
    const selectedDate = this.form.get("date").value;
    const weekday = moment(selectedDate, this.dateFormat, true).day();
    return weekday ? moment().set("hours", this.closingHour[weekday]).startOf("hours").format(this.timeFormat) : "";
  }

  public isOpen() {
    const selectedDate = this.form.get("date").value;
    const now = moment(selectedDate, this.dateFormat, true);
    if (now.isValid) {
      if (this.holidaySettings.some((setting) => moment(setting.key, this.dateFormat, true).isSame(now, "day"))) {
        return false;
      }

      const weekday = now.day();
      return this.startingHour[weekday] > -1 && this.closingHour[weekday] > -1;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
