import { DatePipe } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, merge, of } from "rxjs";
import { catchError, startWith, switchMap, takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ImageDialogComponent } from "src/app/dialogs/image-dialog/image-dialog.component";
import { ShipmentOrderPositionsDialogComponent } from "src/app/dialogs/shipment-order-positions-dialog/shipment-order-positions-dialog.component";
import { CustomIonHeaderCondenseComponent } from "src/app/header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "src/app/header/custom-ion-header/custom-ion-header.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { PagerComponent } from "src/app/includes/pager/pager.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { SortType } from "src/app/models/sort";
import { Tab, TabGroup } from "src/app/models/tab";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { OrderService } from "src/app/services/order.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";
import { IOrderShipmentNote, IOrderShipmentNoteOrder, IOrderShipmentNoteOrderPosition } from "../../models/order-shipment-note";

@Component({
  selector: "app-shipment-notes",
  templateUrl: "./shipment-notes.component.html",
  styleUrls: ["./shipment-notes.component.scss"],
})
export class ShipmentNotesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public environment = environment;
  @ViewChild(CustomIonHeaderCondenseComponent)
  header1: CustomIonHeaderCondenseComponent;
  @ViewChild(CustomIonHeaderComponent) header2: CustomIonHeaderComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("pager1") pager1: PagerComponent;
  @ViewChild("pager2") pager2: PagerComponent;
  @ViewChildren("innerSort") innerSort: QueryList<MatSort>;
  @ViewChildren("innerTables") innerTables: QueryList<MatTable<any>>;
  innerDisplayedColumns: string[] = ["thumbnailFileName", "positionIndex", "articleNumber", "status", "quantityDelivered", "performanceStatementPdf"];
  dataSource = new MatTableDataSource<IOrderShipmentNote>([]);
  expandedRows: IOrderShipmentNote[] = [];
  detailExpandedRows: IOrderShipmentNoteOrder[] = [];
  defaultSort: SortType = {
    type: "createdDate",
    sort: "desc",
  };

  ngUnsubscribe = new Subject<void>();
  refresh = new Subject<void>();
  loadShipmentNotes = () => this.apiOrderService.getShipmentNotes();
  loadShipmentNotesArchive = () => this.apiOrderService.getShipmentNotesArchive();

  public tabGroups: TabGroup[] = [
    {
      dataLoader: this.loadShipmentNotes,
      cacheLoader: () => this.uiShareService.shipmentNotes,
      cacheStorer: (data) => (this.uiShareService.shipmentNotes = data),
      defaultColumns: [
        "expand",
        "shipmentNumber",
        "createdDate",
        "suggestedDeliveryDate",
        "customerReference",
        "shipmentAVPdf",
        "shipmentPdf",
        "actions",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact", "actions"],
      tabs: [
        {
          title: "Aktuell",
          active: true,
          id: 0,
        },
      ],
    },
    {
      dataLoader: this.loadShipmentNotesArchive,
      cacheLoader: () => this.uiShareService.shipmentNotesArchive,
      cacheStorer: (data) => (this.uiShareService.shipmentNotesArchive = data),
      defaultColumns: ["expand", "shipmentNumber", "createdDate", "suggestedDeliveryDate", "customerReference", "shipmentAVPdf", "shipmentPdf", "contact"],
      defaultReadOnlyExcludedColumns: ["contact"],
      tabs: [
        {
          title: "Im Archiv",
          active: false,
          id: 1,
        },
      ],
    },
  ];

  currentTabGroup = this.tabGroups[0];
  currentTab = this.currentTabGroup.tabs[0];
  displayedColumns: string[] = this.getColumnsToDisplay(this.currentTabGroup, this.currentTab);
  currentData: IOrderShipmentNote[] = [];
  mobileData: IOrderShipmentNote[] = [];

  constructor(
    private loaderService: LoaderService,
    private apiOrderService: ApiOrderService,
    public orderService: OrderService,
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private router: RoutingService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.SHIPMENT-NOTES"),
        page: null,
      },
    ];
    this.dataSource.filterPredicate = (data, filter) => {
      let term = data.shipmentNumber + this.getFixedDate(data.createdDate) + this.readableSuggestedDeliveryDate(data);
      data.orders.forEach((o) => (term += o.customerReference + o.orderNumber));
      term = term.toLowerCase().trim();
      filter = filter.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case "customerReference":
          return this.getCustomerReference(data);
        default:
          return data[sortHeaderId];
      }
    };
  }

  ngOnDestroy(): void {
    try {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    merge(this.header1.onReloadClickedSubject, this.header2.onReloadClickedSubject, this.refresh)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          if (this.uiShareService.shipmentNotesNeedReload) {
            this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.needReload = true)));
          }
          return this.getData(this.currentTabGroup, this.currentTab).pipe(catchError(() => of(null)));
        }),
      )
      .subscribe((data: IOrderShipmentNote[]) => {
        this.loaderService.hideLoader();
        this.uiShareService.shipmentNotesNeedReload = false;
        if (data) {
          data = data.sort((a, b) => (b.createdDate as any).localeCompare(a.createdDate as any)); // sort for mobile
          this.storeData(this.currentTabGroup, this.currentTab, data);
          this.currentData = data;
          data.forEach((d) => {
            d.orders.forEach((o) => {
              o.positionsDataSource = new MatTableDataSource<IOrderShipmentNoteOrderPosition>(o.positions);
            });
          });
          this.currentTabGroup.tabs.forEach((tab) => {
            tab.needReload = false;
            tab.count = this.getTabData(tab).length;
          });
          const tabData = this.getCurrentTabData();
          this.dataSource.data = tabData;
          this.pager1.resetPage(this.dataSource.filteredData.length);
          this.updateSearchCount();
        }
      });

    this.globalSearchField.valueChanges.subscribe((term) => {
      this.dataSource.filter = term;
      this.pager1.resetPage(this.dataSource.filteredData.length);
      this.updateSearchCount();
    });

    this.sort.sortChange.subscribe((e) => {
      this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id] = {
        type: e.active,
        sort: e.direction,
      };
      this.uiShareService.saveViewOptions();
    });

    this.pager1.init(1, this.pager2);
    this.pager2.init(2, this.pager1);

    // restore sort
    if (!this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id]) {
      this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id]);

    // restore last tab
    for (let i = 0; i < this.tabGroups.length; i++) {
      for (let j = 0; j < this.tabGroups[i].tabs.length; j++) {
        if (this.tabGroups[i].tabs[j].id == this.uiShareService.currentShipmentNotesTabId) {
          this.switchTab(this.tabGroups[i], this.tabGroups[i].tabs[j]);
          break;
        }
      }
    }
  }

  private getData(tabGroup: TabGroup, tab: Tab): Observable<any> {
    if (!tab.needReload && tabGroup.cacheLoader && tabGroup.cacheLoader()) {
      return of(tabGroup.cacheLoader());
    } else {
      return tabGroup.dataLoader();
    }
  }

  private storeData(tabGroup: TabGroup, tab: Tab, data: any) {
    if (tabGroup.cacheStorer) {
      tabGroup.cacheStorer(data);
    }
  }

  private getCurrentTabData() {
    return this.getTabData(this.currentTab);
  }

  private getTabData(tab: Tab) {
    if (tab.tabFilter) {
      return this.currentData.filter(tab.tabFilter);
    } else {
      return this.currentData;
    }
  }

  private getCachedTabData(tabGroup: TabGroup, tab: Tab): any[] {
    if (tabGroup.cacheLoader) {
      if (tab.tabFilter) {
        return tabGroup.cacheLoader().filter(tab.tabFilter);
      } else {
        return tabGroup.cacheLoader();
      }
    }
    return [];
  }

  private updateSearchCount() {
    this.tabGroups.forEach((tabGroup) => {
      tabGroup.tabs
        .forEach((tab) => {
          if (this.globalSearchField.value && tab.count > 0 && !tab.needReload) {
            const tempDataSource = new MatTableDataSource();
            tempDataSource.filterPredicate = this.dataSource.filterPredicate;
            tempDataSource.data = this.getCachedTabData(tabGroup, tab);
            tempDataSource.filter = this.dataSource.filter;
            tab.searchCount = tempDataSource.filteredData.length;
          } else {
            tab.searchCount = undefined;
          }
        });
    });
  }

  public readableSuggestedDeliveryDate(item: IOrderShipmentNote) {
    return this.datePipe.transform(item.suggestedDeliveryDate, "dd.MM.yyyy HH:mm") + " - " + this.datePipe.transform(this.getAdjustedEndDate(item), "HH:mm");
  }

  public getAdjustedEndDate(item: IOrderShipmentNote): Date {
    if (item.suggestedDeliveryDateTo) {
      if (new Date(item.suggestedDeliveryDateTo).getFullYear() > 1970) {
        return new Date(new Date(item.suggestedDeliveryDateTo).getTime());
      }
    }
    return new Date(new Date(item.suggestedDeliveryDate).getTime() + 1000 * 60 * 60 * 2);
  }

  public openContactForm(mode, item): void {
    this.router.forwardByUrl("/" + mode + "/shipment-notes/" + item.shipmentNumber + "/-");
  }

  public archiveShipment(item?: IOrderShipmentNote): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      data: {
        title: "Lieferung archivieren",
        confirmText: "Möchten Sie die Lieferung mit der Nummer " + item.shipmentNumber + " wirklich archivieren? Dieser Schritt ist nicht umkehrbar.",
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loaderService.showLoaderDelayed().subscribe(() => {
          this.apiOrderService.archiveShipment(item.shipmentNumber).subscribe({
            next: () => {
              this.reloadData(true);

              this.messageService.success(this.translateService.instant("SHIPMENT_NOTES.ARCHIVE_SUCCESS"));
            },
            error: (error) => {
              this.messageService.error(this.translateService.instant("SHIPMENT_NOTES.ARCHIVE_ERROR"));
            },
            complete: () => {
              this.loaderService.hideLoader();
            },
          });
        });
      }
    });
  }

  public reloadData(force = false) {
    if (force) {
      this.uiShareService.shipmentNotesNeedReload = true;
    }
    this.refresh.next();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public previewImage(item?: IOrderShipmentNoteOrderPosition) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "450px",
      data: { item },
    });
  }

  public updateInnerSort() {
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => {
      (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
    });
    // this.cd.detectChanges();
  }

  public loadImages(order: IOrderShipmentNoteOrder) {
    if (order.positions) {
      const tmp = order.positions.filter((p) => p.thumbnailFileBase64);
      if (tmp.length == 0) {
        this.apiOrderService.getOrderImages(order.orderNumber).subscribe((data: IOrderShipmentNoteOrder) => {
          // transfer images
          if (data) {
            data.positions.forEach((p) => {
              const found = order.positions.find((a) => a.positionIndex == p.positionIndex);
              if (found) {
                found.thumbnailFileBase64 = p.thumbnailFileBase64;
              }
            });
            // order.positions = data.positions;
            order.positionsDataSource.data = order.positions;
          }
        });
      }
    }
  }

  public switchTab(tabGroup: TabGroup, tab: Tab) {
    if (tab.active) {
      return;
    }
    this.expandedRows = [];
    this.detailExpandedRows = [];
    this.displayedColumns = this.getColumnsToDisplay(tabGroup, tab);
    const previousLoader = this.currentTabGroup.dataLoader;
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.active = false)));
    tab.active = true;
    this.currentTab = tab;
    this.currentTabGroup = tabGroup;
    this.uiShareService.currentShipmentNotesTabId = this.currentTab.id;
    if (!this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id]) {
      this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentShipmentNotesSortTypes[this.currentTab.id]);
    this.uiShareService.saveViewOptions();
    if (previousLoader !== tabGroup.dataLoader) {
      this.reloadData();
    } else {
      const tabData = this.getCurrentTabData();
      this.dataSource.data = tabData;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    }
  }

  public showShipmentOrderPositions(order: IOrderShipmentNoteOrder) {
    this.loadImages(order as any);
    this.dialog.open(ShipmentOrderPositionsDialogComponent, {
      data: order,
    });
  }

  public getColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.columns || tabGroup.defaultColumns;

    if (tabGroup.defaultReadOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedColumns.includes(c));
    }
    if (tab.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedColumns.includes(c));
    }
    if (tab.excludedColumns) {
      columns = columns.filter((c) => !tab.excludedColumns.includes(c));
    }
    return columns;
  }

  private doSort(sortType: SortType) {
    sortType.sort = sortType.sort.replace("ASC", "asc").replace("DESC", "desc").replace("DSC", "desc") as any; // for legacy support
    if (!sortType.sort) {
      sortType.sort = this.defaultSort.sort;
    }
    if (!sortType.type) {
      sortType.type = this.defaultSort.type;
    }
    this.sort.sort({ id: null, start: sortType.sort, disableClear: true });
    this.sort.sort({ id: sortType.type, start: sortType.sort, disableClear: true });
  }

  public onPageChanged(pagerData: any): void {
    this.mobileData = this.dataSource.filteredData.slice(pagerData.start, pagerData.end);
  }

  public getCustomerReference(row: IOrderShipmentNote) {
    if (!row?.orders) {
      return "";
    }
    const customerReferences = [];
    row.orders.forEach((element) => {
      customerReferences.push(element.customerReference);
    });
    return customerReferences.join("\n");
  }
}
