<app-custom-ion-header #header [selected]="'MENU.FRAME'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header #header (onReload)="reloadData(true)" [selected]="'MENU.FRAME'" [homeIcon]="true" [reloadIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header #header [selected]="'MENU.FRAME'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-condense-header>

  <div>
    <div *ngIf="appMode" style="padding-top: 2em"></div>
    <div>
      <button class="aldra-mobile-fixed-button" *ngIf="appMode && !uiShareService.readOnlyMode()" (click)="freeFrame(selectedFrames())">
        {{ "FRAMES.NEW" | translate }}&nbsp;({{ selectedItemCount() }})</button
      ><br />
    </div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb" [ngClass]="appMode ? 'appMode' : ''">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'FRAMES.TITLE' | translate"></app-content-header>
        <div *ngIf="!appMode" class="reload" (click)="reloadData(true)">
          {{ "COMMON.RELOAD" | translate }}
        </div>
        <br />
        <br />
        <button *ngIf="!uiShareService.readOnlyMode()" (click)="freeFrame(selectedFrames())">
          {{ "FRAMES.NEW" | translate }}&nbsp;({{ selectedItemCount() }})
        </button>
        <br />
        <br />
        <div class="app-header" style="align-items: center">
          <div class="search-comp">
            <app-search-box [formControl]="globalSearchField" placeholder="Suchbegriff"></app-search-box>
          </div>
        </div>
        <br />
        <ng-container *ngFor="let tabGroup of tabGroups">
          <div class="tab" (click)="switchTab(tabGroup, tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabGroup.tabs">
            <ng-container *ngIf="!tab.needReload && tab.count > -1; else nocount"> {{ tab.title }} ({{ tab.searchCount ?? tab.count }}) </ng-container>
            <ng-template #nocount>
              {{ tab.title }}
            </ng-template>
          </div>
        </ng-container>
        <br />
        <br />
        <div class="main-view">
          <div class="desktop">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" (click)="addOrRemove(expandedRows, row); $event.stopPropagation()">
                  <button class="button-table">
                    <span *ngIf="!expandedRows.includes(row)" class="add"></span>
                    <span *ngIf="expandedRows.includes(row)" class="minus"></span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <span>
                    <input [ngModel]="allSelected()" [ngModelOptions]="{ standalone: true }" type="checkbox" (click)="selectAll($event)" />
                  </span>
                </th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                  <span>
                    <input #checkbox type="checkbox" [(ngModel)]="row.selected" [ngModelOptions]="{ standalone: true }" />
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nummer</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.number || "-" }}</td>
              </ng-container>

              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ currentTab.id === 3 ? "Freigemeldet" : "Datum" }}</th>
                <td mat-cell *matCellDef="let row">{{ getFixedDate(row.createdDate) }}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">{{ "FRAME_STATUS." + row.status | translate }}</td>
              </ng-container>

              <ng-container matColumnDef="statusText">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Verhinderungsgrund</th>
                <td mat-cell *matCellDef="let row">{{ row.statusText }}</td>
              </ng-container>

              <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>AuftragsNr.</th>
                <td mat-cell *matCellDef="let row">{{ row.orderNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="merchantReference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Referenz Kunde</th>
                <td mat-cell *matCellDef="let row">{{ row.merchantReference }}</td>
              </ng-container>

              <ng-container matColumnDef="deliverZipCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Plz</th>
                <td mat-cell *matCellDef="let row">{{ row.deliverZipCode }}</td>
              </ng-container>

              <ng-container matColumnDef="deliverCity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Stadt</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.deliverCity }}</td>
              </ng-container>

              <ng-container matColumnDef="deliverStreet">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Straße</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.deliverStreet }} {{ row.deliverHouseNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="deliverCountry">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                <td mat-cell *matCellDef="let row">{{ getCountryName(row.deliverCountry) }}</td>
              </ng-container>

              <ng-container matColumnDef="pickupZipCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Plz</th>
                <td mat-cell *matCellDef="let row">{{ row.pickupZipCode }}</td>
              </ng-container>

              <ng-container matColumnDef="pickupCity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Stadt</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.pickupCity }}</td>
              </ng-container>

              <ng-container matColumnDef="pickupStreet">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Straße</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.pickupStreet }} {{ row.pickupHouseNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="pickupCountry">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                <td mat-cell *matCellDef="let row">{{ getCountryName(row.pickupCountry) }}</td>
              </ng-container>

              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Kontakt</th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                  <div class="message-container">
                    <img class="message mail" (click)="openContactForm('contact', row)" src="./assets/icons/email-02.svg" alt="" />
                    <span class="vertical-line"></span>
                    <img class="message tel" (click)="openContactForm('callback', row)" src="./assets/icons/telefon-01.svg" alt="" />
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!expandedRows.includes(row)">
                  <ng-container *ngIf="expandedRows.includes(row)">
                    <app-frame-detail [frame]="row"></app-frame-detail>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="row.status === 1 ? 'locked' : ''"></tr>
              <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                  Keine Gestellfreigaben gefunden<span *ngIf="currentTab.id !== 3"
                    >, im
                    <a (click)="switchTab(tabGroups[1], tabGroups[1].tabs[0])">Archiv</a>
                    suchen?
                  </span>
                </td>
              </tr>
            </table>
            <mat-paginator
              [hidden]="dataSource.filteredData.length < environment.ENTRIES_PER_PAGE"
              [pageSize]="environment.ENTRIES_PER_PAGE"
              hidePageSize
              showFirstLastButtons
            ></mat-paginator>
          </div>

          <div class="mobile">
            <app-pager #pager1 (pageChanged)="onPageChanged($event)"></app-pager>

            <mat-card class="card mat-mdc-elevation-specific mat-elevation-z2" *ngFor="let item of mobileData; let i = index" appearance="raised">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title class="card-title">
                    <mat-checkbox
                      class="no-ripple-checkbox"
                      color="primary"
                      [disableRipple]="true"
                      *ngIf="currentTab?.id === 0 && !uiShareService.readOnlyMode()"
                      [(ngModel)]="item.selected"
                      [ngModelOptions]="{ standalone: true }"
                    ></mat-checkbox>
                    <span class="title" #highlightCell>{{ item?.number }}</span>
                    <span #highlightCell class="date-mobile" *ngIf="item.createdDate !== null">({{ getFixedDate(item.createdDate) }})</span>
                  </mat-card-title>
                  <mat-card-subtitle #highlightCell>{{ item.merchantReference }}</mat-card-subtitle>
                  <button *ngIf="!uiShareService.readOnlyMode()" class="menu-button more-button" mat-icon-button [matMenuTriggerFor]="actionMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item (click)="openContactForm('contact', item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">email</mat-icon>
                      <span>Kontakt</span>
                    </button>
                  </mat-menu>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content class="content">
                <dl class="grid">
                  <ng-container *ngIf="currentTab.id === 2 || currentTab.id === 3">
                    <dt>Status:</dt>
                    <dd>{{ "FRAME_STATUS." + item.status | translate }}</dd>
                  </ng-container>

                  <dt>AuftragsNr.:</dt>
                  <dd>{{ item.orderNumber }}</dd>

                  <ng-container *ngIf="currentTab.id === 5">
                    <dt>Verhinderungsgrund:</dt>
                    <dd>{{ item.statusText }}</dd>
                  </ng-container>

                  <ng-container *ngIf="currentTab.id === 0">
                    <dt>Lieferadresse:</dt>
                    <dd>
                      <div>{{ item.deliverStreet }} {{ item.deliverHouseNumber }}</div>
                      <div>{{ item.deliverZipCode }} {{ item.deliverCity }}</div>
                      <div>{{ getCountryName(item.deliverCountry) }}</div>
                    </dd>
                  </ng-container>

                  <ng-container *ngIf="currentTab.id !== 0">
                    <dt>Abholadresse:</dt>
                    <dd>
                      <div>{{ item.pickupStreet }} {{ item.pickupHouseNumber }}</div>
                      <div>{{ item.pickupZipCode }} {{ item.pickupCity }}</div>
                      <div>{{ getCountryName(item.pickupCountry) }}</div>
                    </dd>
                  </ng-container>
                </dl>
              </mat-card-content>
            </mat-card>

            <div *ngIf="mobileData.length === 0">Keine Gestellfreigaben gefunden<span *ngIf="currentTab.id !== 3"
              >, im
              <a (click)="switchTab(tabGroups[1], tabGroups[1].tabs[0])">Archiv</a>
              suchen?
            </span></div>
            <app-pager #pager2 (pageChanged)="onPageChanged($event)"></app-pager>
          </div>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
