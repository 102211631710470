import { Component, OnInit, Input, OnChanges, SimpleChanges, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-password-validator",
  templateUrl: "./password-validator.component.html",
  styleUrls: ["./password-validator.component.scss"],
})
export class PasswordValidatorComponent implements OnInit, OnChanges {
  @Input() password: string;
  @Input() successLevel: number;
  @Output() onPasswordValidated = new EventEmitter<boolean>();
  public passwordBarColors = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.password = changes["password"].currentValue;
    this.checkPassword();
  }

  public checkPassword(): void {
    // calculate strength bar
    let strength = 0;
    if (this.password.match("[0-9]")) strength += 1;
    if (this.password.match("[a-z]")) strength += 1;
    if (this.password.match("[A-Z]")) strength += 1;
    if (this.password.match("[!@#$%^&*?_~.,;=]")) strength += 1;
    if (this.password.length >= 8) strength += 1;

    this.passwordBarColors = [];
    if (strength == 0 || strength == 1) {
      this.passwordBarColors = ["red"];
    } else if (strength == 2) {
      this.passwordBarColors = ["red", "red"];
    } else if (strength == 3) {
      this.passwordBarColors = ["orange", "orange", "orange"];
    } else if (strength == 4) {
      this.passwordBarColors = ["orange", "orange", "orange", "orange"];
    } else if (strength == 5) {
      this.passwordBarColors = ["green", "green", "green", "green", "green"];
    }
    this.onPasswordValidated.emit(strength >= this.successLevel);
    //this.passwordStrength = strength;
  }
}
