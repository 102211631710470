import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IFrameEditVM } from "../../models/edit";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiFrameService {
  private dataUrl = environment.apiConfig.uri + "Frames";

  constructor(private http: HttpClient) {}

  public getFrames(): Observable<any> {
    return this.http.get(this.dataUrl + "/getall");
  }
  public getArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/Archive");
  }
  public postFrame(frame: any): Observable<any> {
    return this.http.post(this.dataUrl, frame);
  }

  public editFrame(edit: IFrameEditVM): Observable<any> {
    return this.http.post(this.dataUrl + "/Edit", edit);
  }
}
