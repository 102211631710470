import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { NavigationClient } from "@azure/msal-browser";
import { Capacitor } from "@capacitor/core";
import { BehaviorSubject } from "rxjs";

export class CustomNavigationClient extends NavigationClient {
  private urlSubject = new BehaviorSubject(undefined);

  constructor(private iab: InAppBrowser) {
    super();
  }

  async navigateExternal(url: string, options: any) {
    if (Capacitor.isNativePlatform()) {
      this.urlSubject.next(undefined);
      const browser = this.iab.create(url, "_blank", {
        location: "yes",
        clearcache: "yes",
        clearsessioncache: "yes",
        hidenavigationbuttons: "yes",
        hideurlbar: "yes",
        fullscreen: "yes",
      });
      browser.on("loadstart").subscribe((event) => {
        if (event.url.includes("#state")) {
          this.urlSubject.next(event.url);
          // Close the in app browser and redirect to localhost + the state parameter
          browser.close();
        } else if (event.url.includes("login-failed")) {
          browser.close();
        }
      });
      browser.on("exit").subscribe(() => {
        let url = this.urlSubject.value;
        if (url) {
          const domain = url.split("#")[0];
          //Redirect to blubb to force a reload
          if (Capacitor.getPlatform() === "android") {
            url = url.replace(domain, "https://localhost/blubb");
          } else {
            url = url.replace(domain, "capacitor://localhost/blubb");
          }
          window.location.href = url;
        } else {
          if (Capacitor.getPlatform() === "android") {
            window.location.href = "https://localhost/login-failed";
          } else {
            window.location.href = "capacitor://localhost/login-failed";
          }
        }
      });
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }
    }
    return true;
  }
}
