import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject, of } from "rxjs";
import { catchError, debounceTime, startWith, switchMap, takeUntil } from "rxjs/operators";
import { AdminUserEditDialogComponent } from "src/app/dialogs/admin/admin-user-edit-dialog/admin-user-edit-dialog.component";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogData } from "src/app/models/confirmationDialogData";
import { User } from "src/app/models/user";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  userSearch = new FormControl();
  displayedColumns: string[] = ["merchantNumber", "email", "actions"];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  refresh = new Subject<void>();
  ngUnsubscribe = new Subject<void>();

  constructor(
    private adminService: ApiAdminService,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.dataSource.filterPredicate = (user, filter) => {
      let term = user.merchantNumber + user.email;
      term = term.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    this.refresh
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          return this.adminService.getUsers().pipe(catchError(() => of(null)));
        }),
      )
      .subscribe({
        next: (data: User[]) => {
          this.dataSource.data = data;
          this.loaderService.hideLoader();
        },
        error: (e) => {
          console.log(e);
          this.loaderService.hideLoader();
        },
      });

    this.userSearch.valueChanges.pipe(debounceTime(300)).subscribe((filter) => {
      this.dataSource.filter = filter.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public async editUser(user: User) {
    this.loaderService.showTransparentLoader();
    this.adminService.getUserRequiresMigration(user.id).subscribe({
      next: (requiresMigration) => {
        this.loaderService.hideTransparentLoader();
        this.openEditUserDialog(user, requiresMigration);
      },
      error: (e) => {
        // ignore
        this.loaderService.hideTransparentLoader();
        this.openEditUserDialog(user);
      },
    });
  }

  private openEditUserDialog(user: User, requiresMigration?: boolean) {
    const dialogRef = this.matDialog.open(AdminUserEditDialogComponent, {
      data: { user, requiresMigration },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh.next();
      }
    });
  }

  public confirmPasswordReset(user: User) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Kennwort zurücksetzen",
        confirmText: `Möchten Sie das Kennwort für den Benutzer ${user.email} wirklich zurücksetzen?`,
      } as ConfirmationDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loaderService.showTransparentLoader();
        this.adminService.resetPassword(user.id).subscribe({
          next: (newPW) => {
            this.loaderService.hideTransparentLoader();
            this.matDialog.open(ConfirmationDialogComponent, {
              data: {
                title: "Kennwort zurücksetzen",
                confirmText: `Das Kennwort wurde erfolgreich zurückgesetzt. Das vorläufige Kennwort lautet\n\n${newPW}`,
                hideCloseButton: true,
              } as ConfirmationDialogData,
            });
          },
          error: (e) => {
            this.loaderService.hideTransparentLoader();
            console.error(e);
            this.messageService.error("Beim Zurücksetzen des Kennworts ist ein Fehler aufgetreten.");
          },
        });
      }
    });
  }
}
