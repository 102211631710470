import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Customer } from "src/app/models/customer";
import { User } from "src/app/models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiAdminService {
  private dataUrl = environment.apiConfig.uri + "Admin";

  constructor(private http: HttpClient) {}

  resendMail(type: number, id: string): Observable<any> {
    return this.http.post(this.dataUrl + "/ResendMail", {
      mailType: type,
      uniqueID: id,
    });
  }
  restart(task: string): Observable<any> {
    return this.http.post(this.dataUrl + "/RestartTask/" + task, null);
  }

  getLastRun(task: string): Observable<any> {
    return this.http.post(this.dataUrl + "/LastRun/" + task, null, { responseType: "text" });
  }

  getEstimatedOnlineUsers(): Observable<any> {
    return this.http.get(this.dataUrl + "/EstimatedOnlineUsers");
  }

  getVersion() {
    return this.http.get<string>(this.dataUrl + "/Version");
  }
  getInformationalVersion() {
    return this.http.get(this.dataUrl + "/InformationalVersion", { responseType: "text" });
  }
  getUsers(): Observable<any> {
    return this.http.get(this.dataUrl + "/Users");
  }
  getCustomers(): Observable<any> {
    return this.http.get(this.dataUrl + "/Customers");
  }
  getUserAccess(userId: string): Observable<any> {
    return this.http.get(this.dataUrl + "/UserAccess/" + userId);
  }

  updateAccess(userId: string, merchant: any, type: number, addAccess: boolean) {
    return this.http.put(this.dataUrl + `/UserAccess/${userId}/${type}/${addAccess}`, merchant);
  }
  updateAccountType(userId: string, type: number, addAccess: boolean) {
    return this.http.put(this.dataUrl + `/UserAccountType/${userId}/${type}/${addAccess}`, {});
  }
  updateBatchAccess(userId: string, merchants: any[], type: number, checked: boolean) {
    return this.http.put(this.dataUrl + `/BatchAccess/${userId}/${type}/${checked}`, merchants);
  }

  updateUser(data: User): Observable<any> {
    return this.http.put(this.dataUrl + "/User", data);
  }

  updateCustomer(data: Customer): Observable<any> {
    return this.http.put(this.dataUrl + "/Customer", data);
  }

  getUserRequiresMigration(userId: string) {
    return this.http.get<boolean>(this.dataUrl + `/UserRequiresMigration/${userId}`);
  }

  resetPassword(userId: string) {
    return this.http.get(this.dataUrl + `/UserResetPassword/${userId}`, { responseType: "text" });
  }
}
