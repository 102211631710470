import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "src/app/includes/base/base.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { IOffer } from "src/app/models/offer";
import { IOrder } from "src/app/models/order";
import { ApiDashboardService } from "src/app/services/api/api-dashboard.service";
import { LoaderService } from "src/app/services/loader.service";

import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends BaseComponent implements OnInit {
  public orders1: IOrder[];
  public orders2: IOrder[];
  public offers: IOffer[];
  public breadcrumb: [Breadcrumb];
  public ordersCnt1: string;
  public ordersCnt2: string;
  public offersCnt1: string;

  constructor(
    private router: Router,
    private uiShareService: UiShareService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private apiDashboardService: ApiDashboardService,
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.DASHBOARD"),
        page: null,
      },
    ];
  }

  public ngOnInit(): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiDashboardService.get().subscribe({
        next: (data) => {
          const cnt1 = data && data.ordersToConfirm ? data.ordersToConfirm.length : 0;
          if (data && data.ordersToConfirm && data.ordersToConfirm.length > 3) {
            this.orders1 = data.ordersToConfirm.splice(0, 3);
            this.ordersCnt1 = "3/" + cnt1;
          } else {
            this.orders1 = data.ordersToConfirm;
            this.ordersCnt1 = cnt1 + "/" + cnt1;
          }

          const cnt2 = data && data.ordersInPayment ? data.ordersInPayment.length : 0;
          if (data && data.ordersInPayment && data.ordersInPayment.length > 3) {
            this.orders2 = data.ordersInPayment.splice(0, 3);
            this.ordersCnt2 = "3/" + cnt2;
          } else {
            this.orders2 = data.ordersInPayment;
            this.ordersCnt2 = cnt2 + "/" + cnt2;
          }

          const cnt3 = data && data.offersToConfirm ? data.offersToConfirm.length : 0;
          if (data && data.offersToConfirm && data.offersToConfirm.length > 3) {
            this.offers = data.offersToConfirm.splice(0, 3);
            this.offersCnt1 = "3/" + cnt3;
          } else {
            this.offers = data.offersToConfirm;
            this.offersCnt1 = cnt3 + "/" + cnt3;
          }
          this.loaderService.hideLoader();
        },
        error: (error) => {
          this.loaderService.hideLoader();
          console.error(error);
        },
      });
    });
  }

  public openOrder(item): void {
    this.uiShareService.currentOrderTabId = 0;
    this.router.navigateByUrl("/order", { state: { order: item } });
  }

  public openOffer(item?): void {
    this.uiShareService.currentOfferTabId = 0;
    if (item) {
      this.router.navigateByUrl("/offer", { state: { offer: item } });
    } else {
      this.router.navigateByUrl("/offer");
    }
  }
  public openOrderToPay(item): void {
    this.uiShareService.currentOrderTabId = 1;
    this.router.navigateByUrl("/order", { state: { order: item } });
  }
  public openOrdersTab(type: number): void {
    this.uiShareService.currentOrderTabId = type;
    this.router.navigateByUrl("/order");
  }
}
