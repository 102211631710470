import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { ActionChangeDialogComponent } from "src/app/dialogs/action/action-change-dialog/action-change-dialog.component";
import { AdminOfferDialogComponent } from "src/app/dialogs/admin/admin-offer-dialog/admin-offer-dialog.component";
import { EditIconDialogComponent } from "src/app/dialogs/edit-icon-dialog/edit-icon-dialog.component";
import { ImageDialogComponent } from "src/app/dialogs/image-dialog/image-dialog.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { IOffer } from "src/app/models/offer";
import { IOfferActionHistory, IOfferActionHistoryMessage, IOfferActionHistoryType } from "src/app/models/offer-action-history";
import { IOfferPosition } from "src/app/models/offer-position";
import { Tab } from "src/app/models/tab";
import { ApiOfferService } from "src/app/services/api/api-offer.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { OfferService } from "src/app/services/offer.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { OffersComponent } from "../offers.component";

@Component({
  selector: "app-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
})
export class OfferDetailsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() offer: IOffer;
  @Input() displayedColumns: string[];
  @Input("currentTab") parentTab: Tab;
  @ViewChildren("sort") innerSort: QueryList<MatSort>;
  @ViewChildren("table") innerTables: QueryList<MatTable<any>>;

  tabs: Tab[] = [
    {
      title: "Details",
      active: true,
      id: 0,
    },
    {
      title: "Historie",
      id: 1,
    },
  ];
  currentTab = this.tabs[0];
  detailExpandedRows: IOfferPosition[] = [];
  public lastChangeHistory: any;
  public lastChangeHistoryDate: any;
  public positionMessages: string[] = [];

  constructor(
    private dialog: MatDialog,
    public offerService: OfferService,
    private offersComponent: OffersComponent,
    private apiOfferService: ApiOfferService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    public uiShareService: UiShareService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadImages(this.offer);
    this.loadHistory(this.offer);

    this.offer.positionsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case "description":
          return data["description"] + data["articleNumber"];
        default:
          return data[sortHeaderId];
      }
    };
  }

  ngAfterViewInit(): void {
    this.innerTables.forEach((table, index) => {
      (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
    });
  }

  public switchTab(tab: Tab): void {
    this.tabs.forEach((t) => (t.active = false));
    tab.active = true;
    this.currentTab = tab;
  }

  public updateInnerSort() {
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => {
      (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
    });
    // this.cd.detectChanges();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public previewImage(item?: IOfferPosition) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "450px",
      data: { item },
    });
  }

  openEditIconDialog(item: IOfferPosition) {
    const dialogRef = this.dialog.open(EditIconDialogComponent, {
      width: "470px",
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  public adminEdit(offer: IOffer, position?: IOfferPosition) {
    const dialogRef = this.dialog.open(AdminOfferDialogComponent, {
      width: "450px",
      data: { offerNumber: offer.offerNumber, positionIndex: position ? position.positionIndex : -1 },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.offersComponent.reloadData(true);
      }
    });
  }

  public loadImages(offer: IOffer) {
    this.apiOfferService.getOfferImages(offer.offerNumber).subscribe((data: IOffer) => {
      // transfer images
      if (data) {
        data.positions.forEach((p) => {
          const found = offer.positions.find((a) => a.positionIndex == p.positionIndex);
          if (found) {
            found.thumbnailFileBase64 = p.thumbnailFileBase64;
          }
        });
        // offer.positions = data.positions;
        offer.positionsDataSource.data = offer.positions;
      }
    });
  }

  public loadHistory(offer: IOffer): void {
    // this.loaderService.showLoaderDelayed().subscribe(() => {
    this.apiOfferService.getActionHistory(offer.offerNumber).subscribe({
      next: (data: IOfferActionHistory[]) => {
        // get last change message
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].historyType == 1) {
            this.lastChangeHistoryDate = data[i].createdDate;
            this.lastChangeHistory = JSON.parse(data[i].message);
            break;
          }
        }

        // update for ui
        data = data.sort((h1, h2) => {
          if (h1.createdDate > h2.createdDate) return -1;
          else if (h1.createdDate < h2.createdDate) return 1;
          else return 0;
        });

        data.forEach((h) => {
          if (h.historyType == IOfferActionHistoryType.Release) {
            h.convertedMessage = "In Auftrag überführt";
          } else if (h.historyType == IOfferActionHistoryType.StateChange) {
            let tmp = h.message;
            if (tmp == "Cancelled") tmp = "0";
            else if (tmp == "Created") tmp = "1";
            else if (tmp == "Processing") tmp = "2";
            else if (tmp == "Confirmed") tmp = "3";
            else if (tmp == "Expired") tmp = "5";
            h.convertedMessage = this.translateService.instant("OFFER_STATUS." + tmp);
          } else if (h.historyType == IOfferActionHistoryType.Change) {
            const msg: IOfferActionHistoryMessage = JSON.parse(h.message);
            let tmp = msg.Message;
            for (let i = 0; i < msg.MessagesByPosition.length; i++) {
              const p = msg.MessagesByPosition[i];
              tmp += "<br>Position " + p.Index + ": " + p.Message;
            }
            h.convertedMessage = tmp;
          } else if (
            h.historyType == IOfferActionHistoryType.Contact ||
            h.historyType == IOfferActionHistoryType.Callback ||
            h.historyType == IOfferActionHistoryType.Parked
          ) {
            h.convertedMessage = h.message ? h.message.replace(/\n/g, "<br>") : "";
          }
        });
        offer.historyDataSource.data = data;
        this.tabs[1].count = offer.historyDataSource.data.length;
      },
      error: (error) => {},
      complete: () => {
        this.loaderService.hideLoader();
        if (this.offer) {
          this.offer.positions.forEach((position, idx) => {
            this.positionMessages[idx] = this.getChangeMessageForPosition(position);
          });
        }
      },
    });
    // });
  }

  public getChangeMessageForPosition(item: IOfferPosition): string {
    if (!this.lastChangeHistory) return null;
    const tmp = this.lastChangeHistory.MessagesByPosition;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].Index == item.positionIndex) {
        return tmp[i].Message;
      }
    }
    return null;
  }

  public isChangeMessagesSet(): boolean {
    return this.offer.positions.filter((p) => p.changeMessage).length > 0;
  }

  openActionChangeDialog(item: any) {
    const dialogRef = this.dialog.open(ActionChangeDialogComponent, {
      height: "430px",
      width: "850px",
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const successMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_SUCCESS");
      const errorMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_ERROR");
      if (result == true) {
        this.offersComponent.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public isPastDate(date: string | Date) {
    if (!date) {
      return false;
    }
    return new Date(date) < new Date();
  }
}
