import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MyMonitoringService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        disableTelemetry: !environment.appInsights.instrumentationKey || environment.appInsights.instrumentationKey.trim().length === 0,
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: environment.appInsights.enableAutoRouteTracking, // option to log all route changes
        disableAjaxTracking: environment.appInsights.disableAjaxTracking, // option to log all ajax calls
        disableFetchTracking: environment.appInsights.disableFetchTracking, // option to log all fetch requests
        enableRequestHeaderTracking: environment.appInsights.enableRequestHeaderTracking, // option to log all request headers
        enableResponseHeaderTracking: environment.appInsights.enableResponseHeaderTracking, // option to log all response headers
      },
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
