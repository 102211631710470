import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { User } from "src/app/models/user";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-user-edit-dialog",
  templateUrl: "./admin-user-edit-dialog.component.html",
  styleUrls: ["./admin-user-edit-dialog.component.scss"],
})
export class AdminUserEditDialogComponent implements OnInit {
  public form: FormGroup;
  public countryOptions = [];

  constructor(
    public dialogRef: MatDialogRef<AdminUserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; requiresMigration: boolean },
    private fromBuilder: FormBuilder,
    private uiShareService: UiShareService,
    private adminService: ApiAdminService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    try {
      this.countryOptions = this.uiShareService.getCountryOptions("");
    } catch (e) {
      /* empty */
    }

    this.form = this.fromBuilder.group({
      email: [{ value: this.data.user.email, disabled: true }],
      type: [{ value: this.data.user.type, disabled: this.data.user.type === 0 }, Validators.required],
      firstName: [this.data.user.firstName, Validators.required],
      lastName: [this.data.user.lastName, Validators.required],
      city: [this.data.user.city],
      companyName: [this.data.user.companyName],
      houseNumber: [this.data.user.houseNumber],
      mobile: [this.data.user.mobile],
      phone: [this.data.user.phone],
      street: [this.data.user.street],
      zipCode: [this.data.user.zipCode, Validators.pattern("^[0-9]+$")],
      country: [this.data.user.country],
      merchantNumber: [this.data.user.merchantNumber],
      accountType: [this.data.user.accountType],
      isMainMerchantUser: [{ value: this.data.user.isMainMerchantUser, disabled: this.data.user.isMainMerchantUser }],
      requiresMigration: [{ value: this.data.requiresMigration, disabled: true }],
    });
  }

  save() {
    const formValue = this.form.value;
    const user: Partial<User> = {};
    user.id = this.data.user.id;
    user.type = formValue.type;
    user.firstName = formValue.firstName;
    user.lastName = formValue.lastName;
    user.city = formValue.city;
    user.companyName = formValue.companyName;
    user.houseNumber = formValue.houseNumber;
    user.mobile = formValue.mobile;
    user.phone = formValue.phone;
    user.street = formValue.street;
    user.zipCode = formValue.zipCode;
    user.country = formValue.country;
    user.merchantNumber = formValue.merchantNumber;
    user.accountType = formValue.accountType;
    user.isMainMerchantUser = formValue.isMainMerchantUser || this.data.user.isMainMerchantUser; // nicht enthalten, wenn disabled

    this.adminService.updateUser(user as User).subscribe({
      next: (data) => {
        this.messageService.success("Benutzer konnte erfolgreich geändert werden");
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.messageService.error("Beim Ändern des Benutzers ist ein Fehler aufgetreten.");
      },
    });
  }
}
