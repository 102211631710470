<ion-header></ion-header>
<ion-content>
  <div class="centered">
    <a (click)="login()">Login failed. Please try again.</a>
    @for (event of loginErrors; track $index) {
      <pre>{{ event | json }}</pre>
    }
    Origin: {{ getOrigin() }}
  </div>
</ion-content>
<ion-footer></ion-footer>
