import { AfterViewInit, Component, OnDestroy, ViewChild, effect } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { Observable, Subject, merge, of } from "rxjs";
import { catchError, startWith, switchMap, takeUntil } from "rxjs/operators";
import { CustomIonHeaderCondenseComponent } from "src/app/header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "src/app/header/custom-ion-header/custom-ion-header.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { PagerComponent } from "src/app/includes/pager/pager.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { IFrame, IFrameStatus } from "src/app/models/frame";
import { SortType } from "src/app/models/sort";
import { Tab, TabGroup } from "src/app/models/tab";
import { ApiFrameService } from "src/app/services/api/api-frame.service";
import { LoaderService } from "src/app/services/loader.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-frame",
  templateUrl: "./frames.component.html",
  styleUrls: ["./frames.component.scss"],
})
export class FramesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public environment = environment;
  @ViewChild(CustomIonHeaderCondenseComponent)
  header1: CustomIonHeaderCondenseComponent;
  @ViewChild(CustomIonHeaderComponent) header2: CustomIonHeaderComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("pager1") pager1: PagerComponent;
  @ViewChild("pager2") pager2: PagerComponent;
  dataSource = new MatTableDataSource<IFrame>([]);
  expandedRows: IFrame[] = [];
  sortOptions = [
    { id: "number", name: "Nummer" },
    { id: "createdDate", name: "Datum" },
    { id: "status", name: "Status" },
  ];
  defaultSort: SortType = {
    type: "createdDate",
    sort: "desc",
  };

  ngUnsubscribe = new Subject<void>();
  refresh = new Subject<void>();
  loadFrames = () => this.apiFrameService.getFrames();
  loadFramesArchive = () => this.apiFrameService.getArchive();

  public tabGroups: TabGroup[] = [
    {
      dataLoader: this.loadFrames,
      cacheLoader: () => this.uiShareService.frames,
      cacheStorer: (data) => (this.uiShareService.frames = data),
      defaultColumns: [
        "expand",
        "select",
        "number",
        "createdDate",
        "status",
        "statusText",
        "orderNumber",
        "merchantReference",
        "deliverZipCode",
        "deliverCity",
        "deliverStreet",
        "deliverCountry",
        "pickupZipCode",
        "pickupCity",
        "pickupStreet",
        "pickupCountry",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      tabs: [
        {
          title: "Geliefert",
          id: 0,
          active: true,
          tabFilter: (o) => o.status == IFrameStatus.Delivered,
          excludedColumns: ["status", "statusText", "pickupZipCode", "pickupCity", "pickupStreet", "pickupCountry"],
        },
        {
          title: "Freigemeldet",
          id: 4,
          tabFilter: (o) => o.status == IFrameStatus.PrePickup,
          excludedColumns: ["select", "status", "statusText", "deliverZipCode", "deliverCity", "deliverStreet", "deliverCountry"],
        },
        {
          title: "Abgeholt",
          id: 1,
          tabFilter: (o) => o.status == IFrameStatus.PickedUp,
          excludedColumns: ["select", "status", "statusText", "deliverZipCode", "deliverCity", "deliverStreet", "deliverCountry"],
        },
        {
          title: "In Bearbeitung",
          id: 2,
          tabFilter: (o) => o.status == IFrameStatus.Processing || o.status == IFrameStatus.InPickup || o.status == IFrameStatus.InDelivery,
          excludedColumns: ["select", "statusText", "deliverZipCode", "deliverCity", "deliverStreet", "deliverCountry"],
        },
        {
          title: "Verhindert",
          id: 5,
          tabFilter: (o) => o.status == IFrameStatus.Impediment,
          excludedColumns: ["select", "status", "deliverZipCode", "deliverCity", "deliverStreet", "deliverCountry"],
        },
      ],
    },
    {
      dataLoader: this.loadFramesArchive,
      cacheLoader: () => this.uiShareService.framesArchive,
      cacheStorer: (data) => (this.uiShareService.framesArchive = data),
      defaultColumns: [
        "expand",
        "number",
        "createdDate",
        "status",
        "orderNumber",
        "merchantReference",
        "pickupZipCode",
        "pickupCity",
        "pickupStreet",
        "pickupCountry",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      tabs: [
        {
          title: "Archiv",
          id: 3,
        },
      ],
    },
  ];

  currentTabGroup = this.tabGroups[0];
  currentTab = this.currentTabGroup.tabs[0];
  displayedColumns: string[] = [];
  currentData: IFrame[] = [];
  mobileData: IFrame[] = [];

  constructor(
    private loaderService: LoaderService,
    private apiFrameService: ApiFrameService,
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private router: RoutingService,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.FRAME_LIST"),
        page: null,
      },
    ];
    effect(() => {
      this.getColumnsToDisplay(this.currentTabGroup, this.currentTab);
    });
    this.dataSource.filterPredicate = (data, filter) => {
      let term = "";
      if (this.currentTab.id == 0) {
        term = data.number + data.deliverCity + data.deliverStreet;
      } else {
        term = data.number + data.pickupCity + data.pickupStreet;
      }
      term = term.toLowerCase().trim();
      filter = filter.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case "deliverStreet":
          return data["deliverStreet"] + data["deliverHouseNumber"];
        case "pickupStreet":
          return data["pickupStreet"] + data["pickupHouseNumber"];
        default:
          return data[sortHeaderId];
      }
    };
  }

  ngOnDestroy(): void {
    try {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    merge(this.header1.onReloadClickedSubject, this.header2.onReloadClickedSubject, this.refresh)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          if (this.uiShareService.framesNeedReload) {
            this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.needReload = true)));
          }
          return this.getData(this.currentTabGroup, this.currentTab).pipe(catchError(() => of(null)));
        }),
      )
      .subscribe((data: IFrame[]) => {
        this.loaderService.hideLoader();
        this.uiShareService.framesNeedReload = false;
        if (data) {
          data = data.sort((a, b) => (b.createdDate as any).localeCompare(a.createdDate as any)); // sort for mobile
          this.storeData(this.currentTabGroup, this.currentTab, data);
          this.currentData = data;
          this.currentTabGroup.tabs.forEach((tab) => {
            tab.needReload = false;
            tab.count = this.getTabData(tab).length;
          });
          const tabData = this.getCurrentTabData();
          this.dataSource.data = tabData;
          this.pager1.resetPage(this.dataSource.filteredData.length);
          this.updateSearchCount();
        }
      });

    this.globalSearchField.valueChanges.subscribe((term) => {
      this.dataSource.filter = term;
      this.pager1.resetPage(this.dataSource.filteredData.length);
      this.updateSearchCount();
    });

    this.sort.sortChange.subscribe((e) => {
      this.uiShareService.currentFrameSortTypes[this.currentTab.id] = {
        type: e.active,
        sort: e.direction,
      };
      this.uiShareService.saveViewOptions();
    });

    this.pager1.init(1, this.pager2);
    this.pager2.init(2, this.pager1);

    // restore sort
    if (!this.uiShareService.currentFrameSortTypes[this.currentTab.id]) {
      this.uiShareService.currentFrameSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentFrameSortTypes[this.currentTab.id]);

    // restore last tab
    for (let i = 0; i < this.tabGroups.length; i++) {
      for (let j = 0; j < this.tabGroups[i].tabs.length; j++) {
        if (this.tabGroups[i].tabs[j].id == this.uiShareService.currentFrameTabId) {
          this.switchTab(this.tabGroups[i], this.tabGroups[i].tabs[j]);
          break;
        }
      }
    }
  }

  private getData(tabGroup: TabGroup, tab: Tab): Observable<any> {
    if (!tab.needReload && tabGroup.cacheLoader && tabGroup.cacheLoader()) {
      return of(tabGroup.cacheLoader());
    } else {
      return tabGroup.dataLoader();
    }
  }

  private storeData(tabGroup: TabGroup, tab: Tab, data: any) {
    if (tabGroup.cacheStorer) {
      tabGroup.cacheStorer(data);
    }
  }

  private getCurrentTabData() {
    return this.getTabData(this.currentTab);
  }

  private getTabData(tab: Tab) {
    let data = this.currentData;
    if (tab.tabFilter) {
      data = data.filter(tab.tabFilter);
    }
    return data;
  }

  private getCachedTabData(tabGroup: TabGroup, tab: Tab): any[] {
    if (tabGroup.cacheLoader) {
      if (tab.tabFilter) {
        return tabGroup.cacheLoader().filter(tab.tabFilter);
      } else {
        return tabGroup.cacheLoader();
      }
    }
    return [];
  }

  private updateSearchCount() {
    this.tabGroups.forEach((tabGroup) => {
      tabGroup.tabs
        .forEach((tab) => {
          if (this.globalSearchField.value && tab.count > 0 && !tab.needReload) {
            const tempDataSource = new MatTableDataSource();
            tempDataSource.filterPredicate = this.dataSource.filterPredicate;
            tempDataSource.data = this.getCachedTabData(tabGroup, tab);
            tempDataSource.filter = this.dataSource.filter;
            tab.searchCount = tempDataSource.filteredData.length;
          } else {
            tab.searchCount = undefined;
          }
        });
    });
  }

  public openContactForm(mode, item): void {
    this.router.forwardByUrl("/" + mode + "/frame/" + item.number + "/-");
  }

  public reloadData(force = false) {
    if (force) {
      this.uiShareService.framesNeedReload = true;
    }
    this.refresh.next();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public allSelected() {
    if (this.dataSource.filteredData.length == 0) {
      return false;
    }
    return this.dataSource.filteredData.every((o) => o.selected);
  }

  public selectAll(evt: any) {
    this.dataSource.filteredData.forEach((o) => (o.selected = evt?.currentTarget?.checked));
  }

  public selectedItemCount(): number {
    return this.dataSource.data?.filter((frame) => frame.selected).length;
  }

  public switchTab(tabGroup: TabGroup, tab: Tab) {
    if (tab.active) {
      return;
    }
    this.expandedRows = [];
    this.getColumnsToDisplay(tabGroup, tab);
    const previousLoader = this.currentTabGroup.dataLoader;
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.active = false)));
    tab.active = true;
    this.currentTab = tab;
    this.currentTabGroup = tabGroup;
    this.uiShareService.currentFrameTabId = this.currentTab.id;
    if (!this.uiShareService.currentFrameSortTypes[this.currentTab.id]) {
      this.uiShareService.currentFrameSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentFrameSortTypes[this.currentTab.id]);
    this.uiShareService.saveViewOptions();
    if (previousLoader !== tabGroup.dataLoader) {
      this.reloadData();
    } else {
      const tabData = this.getCurrentTabData();
      this.dataSource.data = tabData;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    }
  }

  public getColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.columns || tabGroup.defaultColumns;

    if (tabGroup.defaultReadOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedColumns.includes(c));
    }
    if (tab.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedColumns.includes(c));
    }
    if (tab.excludedColumns) {
      columns = columns.filter((c) => !tab.excludedColumns.includes(c));
    }
    this.displayedColumns = columns;
  }

  public getCountryName(code: string) {
    return i18nIsoCountries.getName(code, this.translateService.currentLang);
  }

  public selectedFrames() {
    return this.dataSource.data?.filter((f) => f.selected);
  }

  public freeFrame(items: IFrame[]): void {
    if (items == null || items?.length == 0) {
      this.router.forwardByUrl("/frame-create");
    } else {
      this.router.forwardByUrl("/frame-create", {
        state: { frames: items },
      });
    }
  }

  private doSort(sortType: SortType) {
    sortType.sort = sortType.sort.replace("ASC", "asc").replace("DESC", "desc").replace("DSC", "desc") as any; // for legacy support
    if (!sortType.sort) {
      sortType.sort = this.defaultSort.sort;
    }
    if (!sortType.type) {
      sortType.type = this.defaultSort.type;
    }
    this.sort.sort({ id: null, start: sortType.sort, disableClear: true });
    this.sort.sort({ id: sortType.type, start: sortType.sort, disableClear: true });
  }

  public onPageChanged(pagerData: any): void {
    this.mobileData = this.dataSource.filteredData.slice(pagerData.start, pagerData.end);
  }
}
