import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IOfferEdit, IOfferPositionEdit } from "../../models/edit";

@Injectable({
  providedIn: "root",
})
export class ApiOfferService {
  private dataUrl = environment.apiConfig.uri + "Offers";

  constructor(private http: HttpClient) {}

  public getOffers(): Observable<any> {
    return this.http.get(this.dataUrl + "/WithoutImages");
  }
  public getOfferImages(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/OfferImages/" + number);
  }
  public getActionHistory(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/ActionHistory/" + number);
  }
  public getOfferByNumber(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/ByNumber/" + number);
  }
  public archive(offerNumber: string): Observable<any> {
    return this.http.post(this.dataUrl + "/Archive/" + offerNumber, {});
  }
  public park(offerNumber: string): Observable<any> {
    return this.http.post(this.dataUrl + "/Park/" + offerNumber, {});
  }
  public unpark(offerNumber: string): Observable<any> {
    return this.http.post(this.dataUrl + "/UnPark/" + offerNumber, {});
  }
  public approve(approve: any): Observable<any> {
    return this.http.post(this.dataUrl + "/Approve", approve);
  }
  public pdf(object: any, showPricesInPDF: boolean): Observable<any> {
    return this.http.post(this.dataUrl + "/PDF?showPrices=" + showPricesInPDF, object, { observe: "response", responseType: "blob" });
  }
  public getArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/Archive");
  }

  public editOffer(edit: IOfferEdit): Observable<any> {
    return this.http.post(this.dataUrl + "/Edit/Offer", edit);
  }

  public editOfferPosition(edit: IOfferPositionEdit): Observable<any> {
    return this.http.post(this.dataUrl + "/Edit/Position", edit);
  }
  public getOfferImagesPdf(offerNumber: string): Observable<any> {
    return this.http.get(`${this.dataUrl}/OfferImagesPdf/${offerNumber}`, { responseType: "blob" });
  }
  public getOfferArchiveImagesPdf(offerNumber: string): Observable<any> {
    return this.http.get(`${this.dataUrl}/OfferArchiveImagesPdf/${offerNumber}`, { responseType: "blob" });
  }
}
