import buildInfo from "../../buildInfo.json";
export const environment = {
  production: true,
  LOG_LEVEL: 10,
  ENTRIES_PER_PAGE: 25,
  MULTIMERCHANT: true,
  APPROVEAFTERCHANGE: true,
  BUILD_NUMBER: buildInfo.buildNumber,
  msalConfig: {
    auth: {
      clientId: "98637542-ee4d-4e9c-aa25-73ebecbc7b35",
    },
    redirectUri: "https://dev01.aldra.multitrade.hosting/",
    postLogoutRedirectUri: "https://dev01.aldra.multitrade.hosting/",
  },
  apiConfig: {
    scopes: ["https://gcaldratest.onmicrosoft.com/d3635322-9d14-4d01-a26b-98a328bf4be8/TestScope"],
    uri: "https://dev01.aldra.multitrade.hosting/api/",
  },
  b2cPolicies: {
    names: {
      signIn: "B2C_1A_SEAMLESSMIGRATION_SIGNUP_SIGNIN",
      editProfile: "B2C_1A_SEAMLESSMIGRATION_PROFILEEDIT",
    },
    authorities: {
      signIn: {
        authority: "https://gcaldratest.b2clogin.com/gcaldratest.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_SIGNUP_SIGNIN",
      },
      editProfile: {
        authority: "https://gcaldratest.b2clogin.com/gcaldratest.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_PROFILEEDIT",
      },
    },
    authorityDomain: "gcaldratest.b2clogin.com",
  },
  appInsights: {
    instrumentationKey: "a9047a3a-8f88-4469-a504-5eba07b6c53b",
    enableAutoRouteTracking: false, // option to log all route changes
    disableAjaxTracking: true, // option to log all ajax calls
    disableFetchTracking: true, // option to log all fetch requests
    enableRequestHeaderTracking: false, // option to log all request headers
    enableResponseHeaderTracking: false, // option to log all response headers
  },
};
