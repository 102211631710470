import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IInvoiceDTO } from "src/app/models/invoice";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiInvoiceService {
  private dataUrl = environment.apiConfig.uri + "Invoices";

  constructor(private http: HttpClient) {}
  public getInvoices(): Observable<any> {
    return this.http.get(`${this.dataUrl}`);
  }
  getInvoiceAttachment(invoice: IInvoiceDTO): Observable<any> {
    return this.http.post(`${this.dataUrl}/PDF`, invoice, { responseType: "blob" });
  }
  getInvoiceOrderPositions(orderId: string, orderNumber: string): Observable<any> {
    return this.http.get(`${this.dataUrl}/positions/${orderId}/${orderNumber}`);
  }
}
