<app-custom-ion-header #header [selected]="'MENU.OFFER'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header #header (onReload)="reloadData(true)" [selected]="'MENU.OFFER'" [homeIcon]="true" [reloadIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header #header [selected]="'MENU.OFFER'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-condense-header>

  <div>
    <div class="aldra-mobile-fixed-button">
      <button *ngIf="appMode && currentTab?.id !== 4 && !uiShareService.readOnlyMode()" [disabled]="selectedItemCount() === 0" (click)="batchArchive()">
        Archivieren ({{ selectedItemCount() }})
      </button>
      <button *ngIf="appMode && currentTab?.id === 0 && !uiShareService.readOnlyMode()" [disabled]="selectedItemCount() === 0" (click)="batchPark()">
        Parken ({{ selectedItemCount() }})
      </button>
      <button *ngIf="appMode && currentTab?.id === 5 && !uiShareService.readOnlyMode()" [disabled]="selectedItemCount() === 0" (click)="batchUnPark()">
        Parken aufheben ({{ selectedItemCount() }})
      </button>
    </div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb" [ngClass]="appMode ? 'appMode' : ''">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'OFFER.TITLE' | translate"></app-content-header>
        <span *ngIf="!appMode && currentTab?.id !== 4 && !uiShareService.readOnlyMode()">
          &nbsp;<button [disabled]="selectedItemCount() === 0" (click)="batchArchive()">Archivieren ({{ selectedItemCount() || 0 }})</button>
        </span>
        <span *ngIf="!appMode && currentTab?.id === 0 && !uiShareService.readOnlyMode()">
          &nbsp;<button [disabled]="selectedItemCount() === 0" (click)="batchPark()">Parken ({{ selectedItemCount() || 0 }})</button>
        </span>
        <span *ngIf="!appMode && currentTab?.id === 5 && !uiShareService.readOnlyMode()">
          &nbsp;<button [disabled]="selectedItemCount() === 0" (click)="batchUnPark()">Parken aufheben ({{ selectedItemCount() || 0 }})</button>
        </span>
        <div *ngIf="!appMode" class="reload" (click)="reloadData(true)">
          {{ "COMMON.RELOAD" | translate }}
        </div>
        <div class="app-header" style="align-items: center">
          <div class="search-comp">
            <app-search-box [formControl]="globalSearchField"></app-search-box>
          </div>
        </div>
        <br />
        <ng-container *ngFor="let tabGroup of tabGroups">
          <div class="tab" (click)="switchTab(tabGroup, tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabGroup.tabs">
            <ng-container *ngIf="!tab.needReload && tab.count > -1; else nocount"> {{ tab.title }} ({{ tab.searchCount ?? tab.count }}) </ng-container>
            <ng-template #nocount>
              {{ tab.title }}
            </ng-template>
          </div>
        </ng-container>
        <br />
        <br />
        <div class="main-view">
          <div class="desktop">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" (click)="addOrRemove(expandedRows, row); $event.stopPropagation()">
                  <button class="button-table">
                    <span *ngIf="!expandedRows.includes(row)" class="add"></span>
                    <span *ngIf="expandedRows.includes(row)" class="minus"></span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <span>
                    <input [ngModel]="allSelected()" [ngModelOptions]="{ standalone: true }" type="checkbox" (click)="selectAll($event)" />
                  </span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>
                    <input #checkbox type="checkbox" [(ngModel)]="row.selected" [ngModelOptions]="{ standalone: true }" />
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="offerNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorgang</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="expandedRows.includes(row) ? 'identifier' : ''">{{ row.offerNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getFixedDate(row.createdDate) }}</td>
              </ng-container>

              <ng-container matColumnDef="calculatedStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">{{ "OFFER_STATUS." + row.calculatedStatus | translate }}</td>
              </ng-container>

              <ng-container matColumnDef="calculatedAmountNet">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Netto</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.calculatedAmountNet | currency: "EUR" : "symbol" : undefined : "de-DE" }}</td>
              </ng-container>

              <ng-container matColumnDef="calculatedAmountGross">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Brutto</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.calculatedAmountGross | currency: "EUR" : "symbol" : undefined : "de-DE" }}</td>
              </ng-container>

              <ng-container matColumnDef="discountPercentage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rabatt</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.discountPercentage }}%</td>
              </ng-container>

              <ng-container matColumnDef="customerReference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Referenz Kunde</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.customerReference }}</td>
              </ng-container>

              <ng-container matColumnDef="validTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Gültig bis</th>
                <td mat-cell [ngClass]="isPastDate(row.validTo) ? 'past-date' : ''" #highlightCell *matCellDef="let row">{{ getFixedDate(row.validTo) }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let row">
                  <ul class="actions">
                    <li *ngIf="currentTab.id === 0">
                      <a class="link" (click)="openActionChangeOfferDialog(row)">{{ "OFFER.CHANGE" | translate }} </a>
                    </li>
                    <li *ngIf="currentTab.id === 0">
                      <a class="link" (click)="openActionParkDialog(row)">{{ "OFFER.PARK" | translate }} </a>
                    </li>
                    <li *ngIf="currentTab.id !== 4">
                      <a class="link" (click)="openActionArchiveDialog(row)">{{ "OFFER.ARCHIVE" | translate }} </a>
                    </li>
                    <li *ngIf="currentTab.id === 5">
                      <a class="link" (click)="openActionUnParkDialog(row)">{{ "OFFER.UNPARK" | translate }} </a>
                    </li>
                    <li *ngIf="currentTab.id == 6">
                      <a class="link" (click)="openRequestAgainForm(row)">Erneut Anfragen</a>
                    </li>
                  </ul>
                </td>
              </ng-container>

              <ng-container matColumnDef="confirmationPdf">
                <th mat-header-cell *matHeaderCellDef>Angebot</th>
                <td mat-cell *matCellDef="let row">
                  <div
                    *ngIf="row.confirmationPdfType !== 0"
                    class="pdf inline"
                    title="{{ row.offerNumber }}"
                    (click)="offerService.getPdf($event, row, showPricesInPDF)"
                  >
                    <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                  </div>
                  <span *ngIf="row.confirmationPdfType === 0"> - </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="offerImagesPdf">
                <th mat-header-cell *matHeaderCellDef>Element-anhang</th>
                <td mat-cell *matCellDef="let row">
                  <div class="pdf inline" (click)="getOfferImagesPdf(row)">
                    <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Kontakt</th>
                <td mat-cell *matCellDef="let row">
                  <div class="message-container">
                    <img class="message mail" (click)="openContactForm('contact', row)" src="./assets/icons/email-02.svg" alt="" />
                    <span class="vertical-line"></span>
                    <img class="message tel" (click)="openContactForm('callback', row)" src="./assets/icons/telefon-01.svg" alt="" />
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!expandedRows.includes(row)">
                  <ng-container *ngIf="expandedRows.includes(row)">
                    <app-offer-details [offer]="row" [displayedColumns]="innerDisplayedColumns" [currentTab]="currentTab"></app-offer-details>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                  Keine Angebote gefunden<span *ngIf="currentTab.id !== 4"
                    >, im
                    <a (click)="switchTab(tabGroups[1], tabGroups[1].tabs[0])">Archiv</a>
                    suchen?
                </span></td>
              </tr>
            </table>
            <mat-paginator
              [hidden]="dataSource.filteredData.length < environment.ENTRIES_PER_PAGE"
              [pageSize]="environment.ENTRIES_PER_PAGE"
              hidePageSize
              showFirstLastButtons
            ></mat-paginator>
          </div>

          <div class="mobile">
            <app-pager #pager1 (pageChanged)="onPageChanged($event)"></app-pager>

            <mat-card class="card mat-mdc-elevation-specific mat-elevation-z2" *ngFor="let item of mobileData; let i = index" appearance="raised">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title class="card-title">
                    <mat-checkbox
                      class="no-ripple-checkbox"
                      color="primary"
                      [disableRipple]="true"
                      *ngIf="currentTab?.id !== 4 && !uiShareService.readOnlyMode()"
                      [(ngModel)]="item.selected"
                      [ngModelOptions]="{ standalone: true }"
                    ></mat-checkbox>
                    <span class="title" #highlightCell>{{ item.offerNumber }}</span>
                    <span #highlightCell class="date-mobile" *ngIf="item.createdDate !== null">({{ getFixedDate(item.createdDate) }})</span>
                  </mat-card-title>
                  <mat-card-subtitle #highlightCell>{{ item.customerReference }}</mat-card-subtitle>
                  <button *ngIf="!uiShareService.readOnlyMode()" class="menu-button more-button" mat-icon-button [matMenuTriggerFor]="actionMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <button *ngIf="currentTab?.id === 0" mat-menu-item (click)="openActionChangeDialog(item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">update</mat-icon>
                      <span>Ändern/Überführen</span>
                    </button>
                    <button *ngIf="currentTab?.id === 0" mat-menu-item (click)="openActionParkDialog(item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">redo</mat-icon>
                      <span>Parken</span>
                    </button>
                    <button *ngIf="currentTab?.id !== 4" mat-menu-item (click)="openActionArchiveDialog(item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">archive</mat-icon>
                      <span>Archivieren</span>
                    </button>
                    <button *ngIf="currentTab?.id === 5" mat-menu-item (click)="openActionUnParkDialog(item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">undo</mat-icon>
                      <span>Parken Aufheben</span>
                    </button>
                    <button mat-menu-item (click)="openContactForm('contact', item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">email</mat-icon>
                      <span>Kontakt</span>
                    </button>
                  </mat-menu>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content>
                <dl class="grid">
                  <ng-container *ngIf="currentTab?.id === 1 || currentTab?.id === 2">
                    <dt>Status:</dt>
                    <dd>{{ "OFFER_STATUS." + item.calculatedStatus | translate }}</dd>
                  </ng-container>

                  <dt>Brutto:</dt>
                  <dd #highlightCell>{{ item.calculatedAmountGross | currency: "EUR" : "symbol" : undefined : "de-DE" }}</dd>

                  <dt>Rabatt:</dt>
                  <dd #highlightCell>{{ item.discountPercentage }}%</dd>

                  <dt>Netto:</dt>
                  <dd #highlightCell>{{ item?.calculatedAmountNet | currency: "EUR" : "symbol" : undefined : "de-DE" }}</dd>

                  <dt>Gültig bis:</dt>
                  <dd #highlightCell [ngClass]="isPastDate(item.validTo) ? 'past-date' : ''">{{ getFixedDate(item.validTo) }}</dd>
                </dl>
              </mat-card-content>
              <mat-card-actions class="card-actions">
                <button class="menu-button" *ngIf="!item.showDescription" mat-icon-button (click)="item.showDescription = !item.showDescription">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button class="menu-button" *ngIf="item.showDescription" mat-icon-button (click)="item.showDescription = !item.showDescription">
                  <mat-icon>expand_less</mat-icon>
                </button>

                <button class="menu-button" mat-icon-button [matMenuTriggerFor]="pdfMenu">
                  <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                </button>
                <mat-menu #pdfMenu="matMenu">
                  <button *ngIf="item.confirmationPdfType !== 0" mat-menu-item (click)="offerService.getPdf($event, item, showPricesInPDF)">
                    <mat-icon fontSet="material-icons-outlined" class="menu-icon">file_download</mat-icon>
                    <span>Angebot</span>
                  </button>
                  <button mat-menu-item (click)="getOfferImagesPdf(item)">
                    <mat-icon fontSet="material-icons-outlined" class="menu-icon">file_download</mat-icon>
                    <span>Elementanhang</span>
                  </button>
                </mat-menu>
              </mat-card-actions>
              <mat-card-footer class="footer" *ngIf="item.expanded || item.showDescription">
                <app-offer-details [offer]="item" [displayedColumns]="innerDisplayedColumns" [currentTab]="currentTab"></app-offer-details>
              </mat-card-footer>
            </mat-card>

            <div *ngIf="mobileData.length === 0">Keine Angebote gefunden<span *ngIf="currentTab.id !== 4"
                >, im
                <a (click)="switchTab(tabGroups[1], tabGroups[1].tabs[0])">Archiv</a>
                suchen?
            </span></div>
            <app-pager #pager2 (pageChanged)="onPageChanged($event)"></app-pager>
          </div>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
