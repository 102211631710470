import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-logo",
  templateUrl: "./home-logo.component.html",
  styleUrls: ["./home-logo.component.scss"],
})
export class HomeLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
