import { Inject, Injectable, signal } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventError, InteractionType, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";
import packageJson from "../../../package.json";
import { AccountToolsComponent } from "../includes/account-tools/account-tools.component";
import { CommonModalsComponent } from "../includes/common-modals/common-modals.component";
import { IFrame } from "../models/frame";
import { IInvoice } from "../models/invoice";
import { MultiMerchantLink } from "../models/multiMerchantLinks";
import { IOffer } from "../models/offer";
import { IOrder } from "../models/order";
import { IOrderShipmentNote } from "../models/order-shipment-note";
import { IReclamationDb } from "../models/reclamation";
import { SortType } from "../models/sort";
import { MerchantAccount, User } from "../models/user";
import { ApiAdminService } from "./api/api-admin.service";
import { CryptedStorageService } from "./crypted-storage.service";
import { LogService } from "./log.service";
@Injectable({
  providedIn: "root",
})
export class UiShareService {
  public appReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _loggedInUser: BehaviorSubject<User | null> = new BehaviorSubject(null);
  public loggedInUser$ = this._loggedInUser.asObservable();
  private _selectedMerchant: BehaviorSubject<MerchantAccount | null> = new BehaviorSubject(null);
  public selectedMerchant$ = this._selectedMerchant.asObservable();

  public accountTools: AccountToolsComponent;
  public commonModals: CommonModalsComponent;
  public currentOrderTabId = 0;
  public currentOrderSortTypes: SortType[] = [];
  public currentOrderSearchValues;
  public currentShipmentNotesSortTypes: SortType[] = [];
  public currentShipmentNotesTabId = 0;
  public currentShipmentSearchValues;
  public currentOfferTabId = 0;
  public currentOfferSortTypes: SortType[] = [];
  public currentOfferSearchValues;
  public currentReclamationTabId = 0;
  public currentReclamationArchiveTabId = 0;
  public currentReclamationSubTabId = 0;
  public currentReclamationSortTypes: SortType[] = [];
  public currentReclamationSearchValues;
  public currentFrameTabId = 0;
  public currentFrameSortTypes: SortType[] = [];
  public currentFrameSearchValues;
  public currentInvoiceTabId = 0;
  public currentInvoiceSortTypes: SortType[] = [];
  public cameraOrScanMode: number = 0;
  public showPrices = signal(true);
  public multiMerchantLinks: MultiMerchantLink[];
  public gvlSelectionShowed = false;

  public ordersNeedReload: boolean = true;
  public orders: IOrder[];
  public ordersArchive: IOrder[];

  public shipmentNotesNeedReload: boolean = true;
  public shipmentNotes: IOrderShipmentNote[];
  public shipmentNotesArchive: IOrderShipmentNote[];

  public offersNeedReload: boolean = true;
  public offers: IOffer[];
  public offersArchive: IOffer[];

  public reclamationsNeedReload: boolean = true;
  public reclamations: IReclamationDb[];
  public reclamationsArchive: IReclamationDb[];

  public framesNeedReload: boolean = true;
  public frames: IFrame[];
  public framesArchive: IFrame[];

  public invoicesNeedReload: boolean = true;
  public invoices: IInvoice[];
  public invoicesArchive: IInvoice[];

  public loginMessageShown: boolean;
  public backendVersion: string;
  public backendInformationalVersion: string;
  public uiVersion: string;

  public readOnlyMode = signal(false);
  public loginErrors: EventError[] = [];

  constructor(
    private translateService: TranslateService,
    private apiAdminService: ApiAdminService,
    private logService: LogService,
    private cryptedStorageService: CryptedStorageService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    const storageData = this.cryptedStorageService.get("viewOptions");
    const options = JSON.parse(storageData || "{}");
    this.currentFrameSortTypes = options.frames || [];
    this.currentOrderSortTypes = options.orders || [];
    this.currentOfferSortTypes = options.offers || [];
    this.currentReclamationSortTypes = options.reclamations || [];
    this.currentShipmentNotesSortTypes = options.shipments || [];
    this.currentInvoiceSortTypes = options.invoices || [];
    this.currentOrderTabId = options.ordersTab || 0;
    this.currentOfferTabId = options.offersTab || 0;
    this.currentReclamationTabId = options.reclamationsTab || 0;
    this.currentReclamationArchiveTabId = options.reclamationsArchiveTab || 0;
    this.currentReclamationSubTabId = options.reclamationsSubTab || 0;
    this.currentFrameTabId = options.framesTab || 0;
    this.currentShipmentNotesTabId = options.shipmentsTab || 0;
    this.currentInvoiceTabId = options.invoicesTab || 0;
  }

  public setLoggedInUser(user: User) {
    this._loggedInUser.next(user);
  }

  public setSelectedMerchant(merchant: MerchantAccount) {
    this._selectedMerchant.next(merchant);
  }

  public hasAnyRoles(...roles: string[]) {
    const accountRoles = this.authService.instance.getActiveAccount()?.idTokenClaims?.roles || [];
    return roles.some((r) => accountRoles.includes(r));
  }

  public hasAllRoles(...roles: string[]) {
    const accountRoles = this.authService.instance.getActiveAccount()?.idTokenClaims?.roles || [];
    return roles.every((r) => accountRoles.includes(r));
  }

  public loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  public logout() {
    localStorage.removeItem("SwitchUser");
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      this.authService.logoutRedirect();
    }
    if (Capacitor.isNativePlatform()) {
      window.location.reload();
    }
  }

  public loadVersion() {
    this.logService.debug("UiShareService.loadVersion()");
    this.uiVersion = packageJson.version;
    this.apiAdminService.getVersion().subscribe({
      next: (version) => {
        this.backendVersion = version;
      },
      error: (error) => {
        this.logService.debug("UiShareService.loadVersion() error");
        console.log(error);
      },
    });
  }

  public loadInformationalVersion() {
    this.logService.debug("UiShareService.loadInformationalVersion()");
    this.apiAdminService.getInformationalVersion().subscribe({
      next: (version) => {
        this.backendInformationalVersion = version;
      },
      error: (error) => {
        this.logService.debug("UiShareService.loadInformationalVersion() error");
        console.log(error);
      },
    });
  }

  public saveViewOptions() {
    this.cryptedStorageService.set(
      "viewOptions",
      JSON.stringify({
        orders: this.currentOrderSortTypes,
        offers: this.currentOfferSortTypes,
        frames: this.currentFrameSortTypes,
        reclamations: this.currentReclamationSortTypes,
        shipments: this.currentShipmentNotesSortTypes,
        invoices: this.currentInvoiceSortTypes,
        ordersTab: this.currentOrderTabId,
        offersTab: this.currentOfferTabId,
        framesTab: this.currentFrameTabId,
        reclamationsTab: this.currentReclamationTabId,
        reclamationsArchiveTab: this.currentReclamationArchiveTabId,
        reclamationsSubTab: this.currentReclamationSubTabId,
        shipmentsTab: this.currentShipmentNotesTabId,
        invoicesTab: this.currentInvoiceTabId,
      }),
    );
  }

  public setTouchIDData(id, passwd) {
    this.cryptedStorageService.set(
      "tid",
      JSON.stringify({
        uid: id,
        passwd: passwd,
      }),
    );
  }

  public isAdminEditAllowed(): Observable<boolean> {
    return this.loggedInUser$.pipe(
      switchMap((user) => {
        return of(user.type === 0 || !!localStorage.getItem("SwitchUser"));
      }),
    );
  }
  public getCountryOptions(lng: string): any[] {
    const localizedCountryNames = i18nIsoCountries.getNames(this.translateService.currentLang);
    const keys = Object.keys(localizedCountryNames);
    const countryOptions = [];
    for (let i = 0; i < keys.length; i++) {
      countryOptions.push({
        code: keys[i],
        title: localizedCountryNames[keys[i]],
      });
    }
    return countryOptions;
  }
  public getCountryTitle(code: string): string {
    try {
      const localizedCountryNames = i18nIsoCountries.getNames(this.translateService.currentLang);
      const keys = Object.keys(localizedCountryNames);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] == code) {
          return localizedCountryNames[keys[i]];
        }
      }
    } catch (e) {
      /* empty */
    }
    return code;
  }
  public getCountryCode(title: string): string {
    const localizedCountryNames = i18nIsoCountries.getNames(this.translateService.currentLang);
    const keys = Object.keys(localizedCountryNames);
    for (let i = 0; i < keys.length; i++) {
      if (localizedCountryNames[keys[i]] == title) {
        return keys[i];
      }
    }
    return title;
  }

  public getFileSizeStr(bytes: number): string {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb >= 1) {
      const mbRounded = Math.floor(mb);
      const kbLeft = kb - mbRounded * 1024;
      return mbRounded + "," + kbLeft.toFixed(1) + " MB";
    } else {
      return kb.toFixed(1) + " Kb";
    }
  }
}
