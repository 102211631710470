import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, IdTokenClaims, InteractionStatus } from "@azure/msal-browser";
import * as i18nIsoCountries from "i18n-iso-countries";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ConfirmationDialogComponent } from "./dialogs/confirmation-dialog/confirmation-dialog.component";
import { PrivacyDialogComponent } from "./dialogs/privacy-dialog/privacy-dialog.component";
import { AccountToolsComponent } from "./includes/account-tools/account-tools.component";
import { CommonModalsComponent } from "./includes/common-modals/common-modals.component";
import { ConfirmationDialogData } from "./models/confirmationDialogData";
import { MerchantAccessType } from "./models/token";
import { User } from "./models/user";
import { ApiUsersService } from "./services/api/api-users.service";
import { MessageService } from "./services/message.service";
import { UiShareService } from "./services/ui-share.service";
declare let require: any;

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "aldra-meinaldra-portal";
  @ViewChild("accountTools") accountTools: AccountToolsComponent;
  @ViewChild("commonModals") commonModals: CommonModalsComponent;
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;

  constructor(
    public uiShareService: UiShareService,
    private matDialog: MatDialog,
    private apiUsersService: ApiUsersService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private messageService: MessageService,
  ) {
    this.uiShareService.loadVersion();
    this.uiShareService.loadInformationalVersion();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.checkLoginStatus();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.checkLoginStatus();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.checkLoginStatus();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === environment.b2cPolicies.names.signIn || idtoken.tfp === environment.b2cPolicies.names.signIn) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        // Check for errors
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error) {
          if (result.error.message.indexOf("AADB2C90091") > -1) {
            // user cancelled login
            this.uiShareService.loginRedirect();
          } else if (result.error.message.indexOf("authority_mismatch") > -1) {
            // clear storage
            localStorage.clear();
            window.location.reload();
          } else {
            this.uiShareService.loginErrors.push(result.error);
          }
        }
      });
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private checkLoginStatus() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.apiUsersService.getMyUser().subscribe({
        next: (user) => {
          this.uiShareService.setLoggedInUser(user);
          this.uiShareService.setSelectedMerchant({
            merchantNumber: user.merchantNumber,
            companyName: user.companyName,
            email: user.email,
            uniqueID: user.uniqueID,
          });
          this.uiShareService.readOnlyMode.update(() => user.userMerchantAccess.activeMerchantAccessType == MerchantAccessType.READ);
          this.afterLogin(user);
        },
        error: (e: HttpErrorResponse) => {
          if (this.matDialog.openDialogs.length === 0) {
            this.matDialog
              .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                  title: "Fehler bei der Benutzerzuordnung",
                  confirmText: `Dieser Account konnte nicht erfolgreich identifiziert werden. Bitte versuchen Sie es später erneut.\n\n${e.message}`,
                  hideConfirmButton: true,
                  closeButtonLabel: "Logout",
                } as ConfirmationDialogData,
              })
              .afterClosed()
              .subscribe(() => {
                this.uiShareService.logout();
              });
          }
        },
      });
    }
  }

  private afterLogin(user: User) {
    if (!user.legalsAccepted) {
      // show legal terms and conditions
      this.matDialog
        .open(PrivacyDialogComponent, {
          disableClose: true,
        })
        .afterClosed()
        .subscribe(() => {
          this.handleGVL(user);
        });
    } else {
      this.handleGVL(user);
    }
  }

  private handleGVL(user: User) {
    if (user.userMerchantAccess?.isGVL && !this.uiShareService.gvlSelectionShowed && !user.userMerchantAccess?.activeMerchantId) {
      this.uiShareService.accountTools.switchMerchantAccess(true);
      this.uiShareService.gvlSelectionShowed = true;
    }
  }

  ngAfterViewInit() {
    this.uiShareService.accountTools = this.accountTools;
    this.uiShareService.commonModals = this.commonModals;
  }

  public closeView(): void {
    // this.barcodeService.stopScan();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
