import { Component } from "@angular/core";
import { EventError } from "@azure/msal-browser";
import { UiShareService } from "./../../services/ui-share.service";

@Component({
  selector: "app-login-failed",
  templateUrl: "./login-failed.component.html",
  styleUrls: ["./login-failed.component.scss"],
})
export class LoginFailedComponent {
  public loginErrors: EventError[] = [];

  constructor(private uiShareService: UiShareService) {
    this.loginErrors = this.uiShareService.loginErrors;
  }

  login() {
    this.uiShareService.loginRedirect();
  }

  getOrigin() {
    return window.location.origin;
  }
}
