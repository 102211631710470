import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EventLogFilterVM } from "../../models/eventLog";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiEventLogService {
  private dataUrl = environment.apiConfig.uri + "EventLog";

  constructor(private http: HttpClient) {}

  getEventLog(filter: EventLogFilterVM): Observable<any> {
    return this.http.post(this.dataUrl, filter);
  }
}
