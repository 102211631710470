import buildInfo from "../../buildInfo.json";
export const environment = {
  production: true,
  LOG_LEVEL: 10,
  ENTRIES_PER_PAGE: 25,
  MULTIMERCHANT: true,
  APPROVEAFTERCHANGE: true,
  BUILD_NUMBER: buildInfo.buildNumber,
  msalConfig: {
    auth: {
      clientId: "14425ffb-d07e-4560-bcf9-ac42524709ee",
    },
    redirectUri: "https://dev01.aldra.multitrade.hosting/",
    postLogoutRedirectUri: "https://dev01.aldra.multitrade.hosting/",
  },
  apiConfig: {
    scopes: ["https://aldracustomers.onmicrosoft.com/84db0a01-2ba4-402c-a1cf-38da0d34d6b9/MeinAldraScope"],
    uri: "https://dev01.aldra.multitrade.hosting/api/",
  },
  b2cPolicies: {
    names: {
      signIn: "B2C_1A_SEAMLESSMIGRATION_SIGNUP_SIGNIN",
      editProfile: "B2C_1A_SEAMLESSMIGRATION_PROFILEEDIT",
    },
    authorities: {
      signIn: {
        authority: "https://aldracustomers.b2clogin.com/aldracustomers.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_SIGNUP_SIGNIN",
      },
      editProfile: {
        authority: "https://aldracustomers.b2clogin.com/aldracustomers.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_PROFILEEDIT",
      },
    },
    authorityDomain: "aldracustomers.b2clogin.com",
  },
};
