import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import saveAs from "file-saver";
import { NativeFileService } from "src/app/services/native-file.service";
import { DocumentType } from "../models/document";
import { ApiOrderService } from "./api/api-order.service";
import { LoaderService } from "./loader.service";
import { MessageService } from "./message.service";
import { UiShareService } from "./ui-share.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private nativeFileService: NativeFileService,
    private uiShareService: UiShareService,
    private orderService: ApiOrderService,
  ) {}

  public getConfirmationPdf(evt: any, item: any): void {
    evt.stopPropagation();
    let object = null;
    let language = "DE";
    try {
      language = this.translateService.getDefaultLang().toUpperCase();
    } catch (e) {}

    if (item.confirmationPdfType != 0) {
      object = Object.assign({});
      object.orderNumber = item.orderNumber;
      object.positionIndex = -1;
      if (item.confirmationPdfType == 1) {
        object.type = DocumentType.Confirmation;
        object.finalFileName = "Bestaetigung_" + item.orderNumber + ".pdf";
      } else {
        object.type = DocumentType.PreConfirmation;
        object.finalFileName = "Vorabbestaetigung_" + item.orderNumber + ".pdf";
      }
      object.fileName = object.finalFileName;
    }
    if (object) {
      object.orderNumber = item.orderNumber;
    }

    if (object) {
      this._loadPDF(object);
    }
  }
  public getPerformanceStatementPdf(evt: any, item: any): void {
    evt.stopPropagation();
    let object = null;
    let language = "DE";
    try {
      language = this.translateService.getDefaultLang().toUpperCase();
    } catch (e) {}

    if (item.positionIndex != null && item.positionIndex != -1) {
      // position

      if (item.translations != null) {
        object = Object.assign({});
        if (item.type == 0) {
          object.type = DocumentType.PerformanceStatement;
          object.finalFileName = "Leistungserklaerung_" + item.orderNumber + "_" + item.positionIndex + ".pdf";
          object.orderNumber = item.orderNumber;
          object.positionIndex = item.positionIndex;
        }
        object.fileName = item.fileName;
      } else {
        // can only be service statement right now
        object = Object.assign({});
        object.type = DocumentType.PerformanceStatement;
        object.orderNumber = item.orderNumber;
        object.positionIndex = item.positionIndex;
        object.finalFileName = "Leistungserklaerung_" + item.orderNumber + "_" + item.positionIndex + ".pdf";
        object.fileName = object.finalFileName;
      }
    } else {
      // PerformanceStatement from all positions
      object = Object.assign({});
      object.type = DocumentType.PerformanceStatement;
      object.orderNumber = item.orderNumber;
      object.positionIndex = -1;
      object.finalFileName = "Leistungserklaerung_" + item.orderNumber + ".pdf";
      object.fileName = object.finalFileName;
    }
    if (object) {
      this._loadPDF(object);
    }
  }
  public getDocumentPdf(evt: any, item: any): void {
    evt.stopPropagation();
    let object = null;
    let language = "DE";
    try {
      language = this.translateService.getDefaultLang().toUpperCase();
    } catch (e) {}

    if (item.positionIndex != null && item.positionIndex != -1) {
      // position

      if (item.translations != null) {
        object = Object.assign({});
        if (item.type == 0) {
          object.type = DocumentType.PerformanceStatement;
          object.finalFileName = "Leistungserklaerung_" + item.orderNumber + "_" + item.positionIndex + ".pdf";
          object.orderNumber = item.orderNumber;
          object.positionIndex = item.positionIndex;
        } else {
          object.type = DocumentType.ProductInformation;
          if (item.fileName && item.fileName != "") {
            object.finalFileName = item.fileName;
          } else {
            object.finalFileName = item.translations[language].replace(/ /g, "_") + ".pdf";
          }
        }
        object.fileName = item.fileName;
      }
    }

    if (object) {
      this._loadDocumentPDF(object);
    }
  }

  public getShipmentAVPdf(evt: any, item: any): void {
    evt.stopPropagation();
    let object = null;
    object = Object.assign({});
    object.type = DocumentType.DeliveryAvis;
    object.finalFileName = "Sendungsavis_" + item.shipmentNumber + ".pdf";
    object.shipmentNumber = item.shipmentNumber;
    object.fileName = object.finalFileName;
    this._loadPDF(object);
  }
  public getShipmentPdf(evt: any, item: any, item2: any = null): void {
    evt.stopPropagation();
    let object = null;

    if (item2) {
      object = Object.assign({});
      object.type = DocumentType.PerformanceStatement;
      object.orderNumber = item.orderNumber;
      object.positionIndex = item2.positionIndex;
      object.finalFileName = "Leistungserklaerung_" + item.orderNumber + "_" + item2.positionIndex + ".pdf";
      object.fileName = object.finalFileName;
    } else {
      object = Object.assign({});
      object.type = DocumentType.DeliveryBill;
      object.finalFileName = "Lieferschein_" + item.shipmentNumber + ".pdf";
      object.shipmentNumber = item.shipmentNumber;
      object.fileName = object.finalFileName;
    }
    this._loadPDF(object);
  }
  private _loadPDF(object: any) {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.orderService.pdf(object).subscribe({
        next: (data) => {
          if (Capacitor.isNativePlatform()) {
            this.openPdfInApp(data.body, object);
          } else {
            this.loaderService.hideTransparentLoader();
            let filename: string = data.headers.get("content-disposition");
            filename = filename.substring(filename.indexOf("filename=") + "filename=".length);
            saveAs(data.body, filename);
          }
        },
        error: (error) => {
          this.loaderService.hideTransparentLoader();
          this.messageService.error(this.translateService.instant("ORDER.PDF_ERROR"));
        },
      });
    });
  }
  private _loadDocumentPDF(object: any) {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.orderService.documentPDF(object).subscribe({
        next: (data) => {
          if (Capacitor.isNativePlatform()) {
            this.openPdfInApp(data, object);
          } else {
            this.loaderService.hideTransparentLoader();
            let filename: string = data.headers.get("content-disposition");
            filename = filename.substring(filename.indexOf("filename=") + "filename=".length);
            saveAs(data.body, filename);
          }
        },
        error: (error) => {
          this.loaderService.hideTransparentLoader();
          this.messageService.error(this.translateService.instant("ORDER.PDF_ERROR"));
        },
      });
    });
  }
  private openPdfInApp(data: any, object: any) {
    this.nativeFileService.openPdf(data, object);
  }
  public getOrderImagesPdf(orderNumber: string) {
    return this.orderService.getOrderImagesPdf(orderNumber);
  }
  public getOrderArchiveImagesPdf(orderNumber: string) {
    return this.orderService.getOrderArchiveImagesPdf(orderNumber);
  }
}
